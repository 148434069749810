import Container from '../../UI/Container/Container'
import SectionHeader from '../../UI/SectionHeader/SectionHeader'
import Subtitle from '../../UI/Subtitle/Subtitle'
import Title from '../../UI/Title/Title'
import styles from './Faq.module.css'
import FaqItem from './FaqItem'
import FaqInfo from '../../../assets/faq/faq-text'
import Link from '../../UI/Link/Link'
import {FormattedMessage} from 'react-intl'
import { useContext } from 'react'
import LangContext from '../../../Context/LangContext'


const Faq = props => {

   const lang = useContext(LangContext)

   let faqList;

   if (lang === 'RUSSIAN') {
      faqList = FaqInfo.map((faq, index) => <FaqItem title={faq.ruTitle} body={faq.ruBody} key={index} animated={index % 2 == 0 ? 'fade-right' : 'fade-left'} delay={index * 150} />)
   } else {
      faqList = FaqInfo.map((faq, index) => <FaqItem title={faq.enTitle} body={faq.enBody} key={index} animated={index % 2 == 0 ? 'fade-right' : 'fade-left'} delay={index * 150} />)
   }


   return <section className={styles.faq} id="faq">
      <Container>
         <SectionHeader>
            <Title text={<FormattedMessage id='faq-title' />} />
            <Subtitle text={<FormattedMessage id='faq-subtitle' />} />
         </SectionHeader>
      </Container>
      <div className={styles.wrapper}>
         <Container>
            <div className={styles.inner}>
               {faqList}
            </div>
         </Container>
      </div>
      <Container>
         <div className={styles.help__block} data-aos="fade-up" aos-duration="800">
            <p className={styles['help__block-text']}><FormattedMessage id='faq-additional-text' /></p>
            <Link text={<FormattedMessage id='faq-additional-btn' />} link="/base-of-knowledge" />
         </div>
      </Container>
   </section>
}

export default Faq