import { useRef, useState } from 'react'
import styles from './FooterColumn.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const FooterColumn = props => {

   const [isOpen, setIsOpen] = useState(false)


   const ulist = props.list.map((item, index) => {
      return <li className={styles.footer__item} key={index}>
         <Link className={styles.footer__link} to={item.link}>{item.text}</Link>
      </li>
   })

   const toggleAccardionHandle = e => {
      setIsOpen(!isOpen)
   }

   const open = isOpen ? styles.open : ''


   return <div className={styles.footer__column}>
      <div onClick={toggleAccardionHandle} className={styles['footer__column-accardion']}>
         <h4 className={styles['footer__accardion-title']}>{props.title}</h4>
         {isOpen ? <FontAwesomeIcon className={styles['footer__accardion-icon']} icon={faAngleUp} /> : <FontAwesomeIcon className={styles['footer__accardion-icon']} icon={faAngleDown} />}
      </div>
      <h4 className={styles['footer__column-title']}>{props.title}</h4>
      <ul className={styles['footer__column-list'] + ' ' + open} >
         {ulist}
      </ul>
   </div>
}

export default FooterColumn