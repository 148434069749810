import Link from '../../UI/Link/Link';
import styles from './PriceCard.module.css';
import {FormattedMessage} from 'react-intl'


const PriceCard = props => {

   let classes = styles.card

   if (props.className) {
      classes = styles.card + ' ' + props.className
   }

   let white = ''
   
   if (props.white) {
      white = styles.white
   }

   const descList = props.list.map((item, index) => <li className={styles.card__item} key={index}>{item}</li>)

	return (
		<div data-aos-delay={props['data-aos-delay']} className={classes} data-aos={props['data-aos']} data-aos-duration="500">
         {white ? <span className={styles.card__tag}><FormattedMessage id="price-best-value" /></span> : ''}
			<span className={styles.card__period}>{props.period}</span>
			<span className={styles.card__price + ' ' + white}>{props.price} ₽</span>
			<ul className={styles.card__list}>
				{descList}
			</ul>
         <Link text={props.linkText} link={props.link} className={styles.card__btn + ' ' + white} />
		</div>
	);
};

export default PriceCard;
