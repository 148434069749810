export default [
	{
		ruTitle: 'Общие условия',
		ruBody: (
			<p>
				Настоящее Соглашение с Условиями является юридически обязывающим
				пользовательским соглашением, заключенное между лицом («Клиент») и
				Администрацией сайта https://asaproxy.re/ (“Администратор”),
				относительно доступа клиента и использование https://asaproxy.re/
				сайта (“Система”). Система предназначена для личного пользования и
				только в правомерных и законных целях, как это определено в
				настоящем Соглашении с Условиями, так и любые соответствующие
				законы. Нажатием на кнопку "Купить" в Системе и/или начав
				использовать Систему, Клиент автоматически подтверждает, что
				ознакомился с настоящим Соглашением, а также Клиент заключил
				юридически обязательное Соглашение с Администратором. Если Клиент не
				согласен с условиями настоящего Соглашения, он не должен
				использовать или должен немедленно прекратить использование Системы.
			</p>
		),
		enTitle: 'General conditions',
		enBody: (
			<p>
				This Terms and Conditions Agreement is a legally binding user
				agreement entered into between a person (the "Client") and the
				https://asaproxy.re/ website Administration (the "Administrator")
				regarding the Client's access to and use of the https://asaproxy.re/
				website (the "System"). The System is intended for personal use and
				only for lawful and lawful purposes as defined in this Agreement
				with the Terms and any applicable laws. By clicking on the "Buy"
				button in the System and/or commencing use of the System, the
				Customer automatically confirms that he has read this Agreement and
				the Customer has entered into a legally binding Agreement with the
				Administrator. If the Customer does not agree to the terms and
				conditions of this Agreement, the Customer shall not use or shall
				immediately cease using the System.
			</p>
		),
	},
	{
		ruTitle: 'Предмет договора',
		ruBody: (
			<>
				<p>
					При условии согласия и постоянным соблюдением настоящего
					Договора, Администратор предоставляет неисключительную,
					непередаваемую, подлежащую отмене ограниченную лицензию для
					доступа и использование Системы в личных и только в законных
					коммерческих целях, в течение всего срока действия, выбранного
					Клиентом предоставляемого Системой пакета подключения. Выбранный
					Клиентом абонентский пакет Системы, устанавливает
					продолжительность и технические ограничения на использование
					Системы. Клиент должен ввести действующий способ оплаты в
					качестве условия для использования или доступа к Системе, и
					оплата за выбранный пакет подписки Системы будет начисляться по
					нему.
				</p>
				<p>
					Клиент соглашается с тем, что будет соблюдать все законы и
					правила при использовании Системы. Администратор оставляет за
					собой право в любое время прекратить предоставление услуг и/или
					поддерживать Систему или ее часть, а также любые составные
					функции входящие в Систему, в этот момент клиентская лицензия на
					использование Системы или ее часть будет автоматически
					приостановлена. В таком случае Администратор не обязан
					предоставлять возмещение, льготы или другую компенсацию
					пользователям Системы.
				</p>
				<p>
					Администратор оставляет за собой право в одностороннем порядке
					отказать в Системе, по собственному усмотрению приостановить
					использование Клиента или ограничить доступ к Системе по любой
					причине или вовсе без причины (см. Также раздел «Запрещенные
					действия» ниже).
				</p>
				<p>
					Лица моложе 18 лет не должны пользоваться Системой. Администратор
					никогда сознательно не должен разрешать использование Системы
					лицам младше 18 лет и не собирает, и не обрабатывает какие-либо
					личные данные таких лиц.
				</p>
				<p>
					Клиент должен предоставить Администратору информацию для
					регистрации учетной записи клиента (действующий адрес электронной
					почты и пароль) и информацию об оплате для использования Системы.
					Клиент несет полную ответственность за актуальность, полноту и
					точность данной информации.
				</p>
			</>
		),
		enTitle: 'Subject of the contract',
		enBody: (
			<>
				<p>
					Provided consent and continued compliance with this Agreement,
					the Administrator grants a non-exclusive, non-transferable,
					revocable limited license to access and use the System for
					personal and lawful commercial purposes only, for the duration of
					the Customer's chosen subscription package provided by the
					System. The System subscription package chosen by the Customer
					shall establish the duration and technical limitations on the use
					of the System. The Customer shall enter a valid payment method as
					a condition of use or access to the System and payment for the
					chosen System subscription package shall accrue thereunder.
				</p>
				<p>
					The customer agrees that he/she will comply with all laws and
					regulations when using the System. The Administrator reserves the
					right to discontinue providing services and/or maintaining the
					System or any component features included in the System at any
					time, at which time the customer's license to use the System or
					any part thereof shall automatically be suspended. In such an
					event, the Administrator shall not be obligated to provide any
					refund, benefits or other compensation to users of the System.
				</p>
				<p>
					The Administrator reserves the right to unilaterally deny use of
					the System, suspend Customer use, or restrict access to the
					System for any reason or no reason at all (see also the
					"Prohibited Actions" section below).
				</p>
				<p>
					People under the age of 18 should not use the System. The
					Administrator should never knowingly permit anyone under 18 to
					use the System and does not collect, nor handle any personal
					information about them.
				</p>
				<p>
					The Customer must provide Administrator with customer account
					registration information (a valid email address and password) and
					payment information to use the System. The Customer shall be
					solely responsible for the timeliness, completeness and accuracy
					of such information.
				</p>
			</>
		),
	},
	{
		ruTitle: 'Запрещенные действия',
		ruBody: (
			<>
				<p>
					Используя Систему Клиент соглашается не одобрять и не
					поддерживать, не поощрять или не разрешать другим использовать
					для/в Системе (совместно именуемые «Запрещенные действия»):
				</p>
				<ul>
					<li>
						Нарушать общие этические или моральные нормы,
						добропорядочность и справедливое поведение;
					</li>
					<li>
						Нарушать какие-либо права третьих лиц, в том числе любые
						нарушения прав собственности, конфиденциальности, доверия,
						личных данных, авторских прав, товарных знаков, патентов или
						любых других прав интеллектуальной собственности или прав
						собственности;
					</li>
					<li>
						Участвовать в любой террористической, военной, полувоенной,
						ополчение или аналогичной подготовке и деятельности;
					</li>
					<li>
						Участвовать в любых запрещенных, криминальных или иных
						незаконных действиях, включая нарушения безопасности и
						конфиденциальности;
					</li>
					<li>
						Шпионить, преследовать, домогаться, разыгрывать или
						преследовать других, или поощрять фанатизм или дискриминацию,
						в целях проведения шпионской деятельности сделать любой
						сетевой ресурс недоступным для его пользователей, включая
						атаки Отказ в Обслуживании (ОВО) и Распределенный Отказ в
						Обслуживание (РОВО);
					</li>
					<li>
						Применять любые повышенные риски к третьим лицам, вредные или
						разрушительные действий, включая распространение вирусов,
						рекламного ПО, червей, троянов, вредоносных и шпионских
						программ, или любых других подобных злоумышленных действий и
						продуктов, или любого другого компьютерного кода, файлов или
						программ, предназначенных для прерывания, захватывания,
						уничтожения, ограничения или оказывающие отрицательное влияние
						на функциональность любого компьютерного программного,
						аппаратного, сетевого или телекоммуникационного оборудования;
					</li>
					<li>
						Хакерские действия, взлом, распространение вирусов,
						мошеннические действия, саботаж в сети, фишинг;
					</li>
					<li>
						Использовать в отношении несовершеннолетних, и особенно для
						наблюдения, доступа или распространения детской порнографии;
						другие виды деятельности, которые могут причинить вред для
						несовершеннолетних;
					</li>
					<li>
						Деятельность, которая притесняет или выступает за
						преследование другого лица;
					</li>
					<li>
						Деятельность или распространённая информации, которая является
						мошеннической, ложной или вводящей в заблуждение или
						осуществляется незаконно;
					</li>
					<li>
						Действия или поведения, которые являются оскорбительными,
						угрожающими, непристойными, дискредитирующими или
						клеветническими;
					</li>
					<li>
						Нарушать любой применимый закон или совершать действия,
						которые являются незаконными в любой юрисдикции;
					</li>
					<li>
						Нарушать настоящее Соглашение или Политику Конфиденциальности;
					</li>
					<li>
						Изменять, адаптировать, присваивать, воспроизводить,
						распространять, переводить, создавать производные работы или
						адаптации, публично демонстрировать, продавать, обменивать или
						иным образом использовать Систему;
					</li>
					<li>
						Использовать любую робототехнику, приложение или другое
						автоматизированное устройство, как процесс или средство для
						доступа к Системе;
					</li>
					<li>
						Изменять любые функциональные возможности, интерфейс или любую
						другую часть Системы;
					</li>
					<li>
						Удалять или изменять любые уведомления об авторских правах,
						товарных знаках или других правах собственности, которые
						появляются в любой части Системы или в любом Пользовательском
						контенте полученном или скопированном из Системы;
					</li>
					<li>
						Пытаться получить несанкционированный доступ к Системе, ее
						функциям и особенностям, компьютерной системе или сетям, к
						которым Система может быть подключена;
					</li>
					<li>
						Использовать Систему нарушая конфиденциальность, безопасность
						или иным образом причинять вред имуществу, физическим и / или
						юридическим лицам;
					</li>
					<li>
						Использовать Систему таким образом, чтобы это вызывало
						необоснованную или непропорционально большую нагрузку на
						Систему или нашу техническую или программную инфраструктуру;
					</li>
					<li>
						Заниматься другими непропорциональными и в целом неприемлемыми
						видами деятельности.
					</li>
				</ul>
				<p>
					Клиент несет ответственность за надлежащую безопасную
					конфигурацию используемого программного обеспечения и несет
					ответственность за любой ущерб, вызванный его пренебрежением или
					подверженности его к уязвимости, независимо от того преднамеренно
					это или непреднамеренно.
				</p>
			</>
		),
      enTitle: 'Prohibited activities',
      enBody: <>
      <p>
      By using the System, the Client agrees not to endorse or
      support, discourage or permit others to use
      for/in the System (collectively referred to as the "Prohibited Activities"):
      </p>
      <ul>
      <li>
      Violate general ethical or moral norms,
      integrity and fair conduct;
      </li>
      <li>
      Violate any rights of third parties, including any
      violations of property rights, confidentiality, trust,
      personal data, copyrights, trademarks, patents or
      any other intellectual property rights or rights
      property;
      </li>
      <li>
      Participate in any terrorist, military, paramilitary,
      militia or similar training and activities;
      </li>
      <li>
      Engage in any prohibited, criminal or other
      illegal activities, including breaches of security and
      privacy;
      </li>
      <li>
      Spying, stalking, harassing, playing tricks or
      harass others, or encourage bigotry or discrimination,
      for the purpose of conducting espionage activities, make any
      network resource inaccessible to its users, including
      Denial of Service (DOS) and Distributed Denial of Service attacks
      Service (ROVO);
      </li>
      <li>
      Apply any increased risks to third parties, harmful or
      destructive actions, including the spread of viruses,
      adware, worms, trojans, malware and spyware
      programs, or any other similar malicious activity and
      products, or any other computer code, files or
      programs designed to interrupt, capture,
      destroying, restricting or adversely affecting
      on the functionality of any computer software,
      hardware, network or telecommunications equipment;
      </li>
      <li>
      Hacking, hacking, spreading viruses,
      fraudulent activities, network sabotage, phishing;
      </li>
      <li>
      Use on minors, and especially for
      watching, accessing or distributing child pornography;
      other activities that may be harmful to
      minors;
      </li>
      <li>
      Activities that harass or advocate for
      harassment of another person;
      </li>
      <li>
      Activities or disseminated information that is
      fraudulent, false or misleading or
      carried out illegally;
      </li>
      <li>
      Actions or behaviors that are offensive,
      threatening, obscene, defamatory or
      slanderous;
      </li>
      <li>
      Violate any applicable law or act
      which are illegal in any jurisdiction;
      </li>
      <li>
      Violate this Agreement or the Privacy Policy;
      </li>
      <li>
      Modify, adapt, appropriate, reproduce,
      distribute, translate, create derivative works of, or
      adaptation, public display, sale, exchange or
      otherwise use the System;
      </li>
      <li>
      Use any robotics, application or other
      an automated device, as a process or means for
      access to the System;
      </li>
      <li>
      Change any functionality, interface, or any
      another part of the System;
      </li>
      <li>
      Remove or modify any copyright notices,
      trademarks or other proprietary rights that
      appear in any part of the System or in any User
      content received or copied from the System;
      </li>
      <li>
      Attempt to gain unauthorized access to the System, its
      functions and features, computer system or networks, to
      to which the System can be connected;
      </li>
      <li>
      Use the System in violation of confidentiality, security
      or otherwise cause harm to property, persons and/or
      legal entities;
      </li>
      <li>
      Use the System in a way that causes
      unreasonable or disproportionate burden on
      the System or our technical or software infrastructure;
      </li>
      <li>
      Engage in other disproportionate and generally unacceptable
      activities.
      </li>
      </ul>
      <p>
      The customer is responsible for proper security
      configuration of the software used and bears
      responsible for any damage caused by its neglect or
      exposure to vulnerability, whether intentionally
      this is either unintentional.
      </p>
      </>
	},
];
