import Container from '../../UI/Container/Container'
import SectionHeader from '../../UI/SectionHeader/SectionHeader'
import Subtitle from '../../UI/Subtitle/Subtitle'
import Title from '../../UI/Title/Title'
import styles from './CheckIpFaq.module.css'
import CheckIpFaqItem from './CheckIpFaqItem'
import CheckIpFaqInfo from '../../../assets/checkIpFaq/faq-text'
import Link from '../../UI/Link/Link'
import {FormattedMessage} from 'react-intl'
import { useContext } from 'react'
import LangContext from '../../../Context/LangContext'


const CheckIpFaq = props => {

   const lang = useContext(LangContext)

   let faqList;

   if (lang === 'RUSSIAN') {
      faqList = CheckIpFaqInfo.map((faq, index) => <CheckIpFaqItem title={faq.ruTitle} body={faq.ruBody} key={index} />)
   } else {
      faqList = CheckIpFaqInfo.map((faq, index) => <CheckIpFaqItem title={faq.enTitle} body={faq.enBody} key={index} />)
   }


   return <section className={styles.faq} id="faq">
      <div className={styles.wrapper}>
         <Container>
            <div className={styles.inner} data-aos="fade-left" data-aos-duration="400">
               {faqList}
            </div>
         </Container>
      </div>
      <Container>
         <div className={styles.help__block} data-aos="fade-up" aos-duration="800">
            <p className={styles['help__block-text']}><FormattedMessage id='faq-additional-text' /></p>
            <Link text={<FormattedMessage id='faq-additional-btn' />} link="/base-of-knowledge" />
         </div>
      </Container>
   </section>
}

export default CheckIpFaq