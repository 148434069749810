import Input from '../UI/Input/Input';
import styles from './AdminLogin.module.css';
import showIcon from '../../assets/img/show.png'
import hideIcon from '../../assets/img/hide.png'
import { useState, useEffect } from 'react';
import CheckBox from '../UI/CheckBox/CheckBox';
import {FormattedMessage} from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import ReactTooltip from 'react-tooltip';
import tooltipIcon from '../../assets/img/tooltip.png'

const AdminLogin = props => {
   const [isShowPassword, setIsShowPassword] = useState(false)
   const [inputUsername, setInputUsername] = useState(null)
   const [inputPassword, setInputPassword] = useState(null)
	const [isChecked, setIsChecked] = useState(false);
   const [isFormValid, setIsFormValid] = useState(false)
   const [isUserFieldValid, setIsUserFieldValid] = useState(true)
   const [isPassFieldValid, setIsPassFieldValid] = useState(true)

   useEffect(() => {
      if (inputUsername !== null && inputPassword !== null)
      setIsFormValid(!/[^\a-zA-Z0-9]+/g.test(inputUsername) && inputUsername.trim().length > 2 && inputUsername.trim().length < 26 && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!?<>\[\]'"@#\$%\^\&*\\\)\/\(\+\=\.\,\_\-\~\`\|«»\—\-])[A-Za-z\d!?<>\[\]'"@#\$%\^\&*\\\)\/\(+=.,_-~`|«»—\-]+$/g.test(inputPassword) && inputPassword.trim().length > 7 && inputPassword.trim().length < 78 && inputPassword !== inputUsername)

   }, [inputUsername, inputPassword])

   useEffect(() => {

      const timer = setTimeout(() => {
         if (inputUsername !== null) {
            setIsUserFieldValid(!/[^\a-zA-Z0-9]+/g.test(inputUsername) && inputUsername.trim().length > 2 && inputUsername.trim().length < 26)
         }
      }, 500)
            
      return () => {
         clearTimeout(timer)
      }
   }, [inputUsername])

   useEffect(() => {

      const timer = setTimeout(() => {
         if (inputPassword !== null) {
            setIsPassFieldValid(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&"'.,\\\/\-_=+\(\)<>{}\[\]~`|^—«»])[A-Za-z\d@$!%*#?&"'.,\\\/\-_=+\(\)<>{}\[\]~`|^—«»]{8,77}$/.test(inputPassword) && inputPassword !== inputUsername)
         }
      }, 500)
            
      return () => {
         clearTimeout(timer)
      }
   }, [inputPassword])


   const passwordShowHandler = e => {
      setIsShowPassword(!isShowPassword)
   }

   const usernameHandler = e => {

      if (/\s/.test(e.target.value)) {
         e.target.value = e.target.value.replace(/\s/, '');
      }

      if (/[A-Z]+/.test(e.target.value)) {
         e.target.value = e.target.value.toLowerCase()
      }
      
      setInputUsername(e.target.value)
   
   }

   const passwordHandler = e => {
      
      if (/\s/.test(e.target.value)) {
         e.target.value = e.target.value.replace(/\s/, '');
      }

      setInputPassword(e.target.value)

   }

   const fromSubmitHandler = e => {
      e.preventDefault()
      console.log({
         username: inputUsername,
         password: inputPassword,
         remember: isChecked
      })
   }

	return (
		<div className={styles.inner}>
			<form action="#" className={styles.form}>
				<Input
					type="name"
					placeholder="Username"
					className={styles.form__input + ' ' + (!isUserFieldValid && styles.invalid)}
					onChange={usernameHandler}
				/>
				<div className={styles.password__inner}>
					<Input
						type={isShowPassword ? 'text' : 'password'}
						placeholder="Password"
						onChange={passwordHandler}
						className={styles.form__input + ' ' + (!isPassFieldValid && styles.invalid)}
					/>
					<span
						className={styles.password__btn}
						onClick={passwordShowHandler}
					>
						<img
							src={isShowPassword ? hideIcon : showIcon}
							alt="password icon"
						/>
					</span>
				</div>
            <div className={styles.remember}>
               <CheckBox label="Запомнить меня" isChecked={isChecked} setIsChecked={setIsChecked} />
            </div>
            <button disabled={!isFormValid} type="submit" className={styles.btn}>Войти</button>
            <CSSTransition in={!isUserFieldValid} timeout={500} classNames="showAnim" unmountOnExit>
                        <div className={styles.error} >
                           <p className={styles.error__text}><FormattedMessage id="sign-error-user-title"/></p>
                           <span className={styles.tooltip}>
                              <p data-for="user-error" data-tip><img src={tooltipIcon} className={styles.tooltip__icon} alt="tooltip icon" /></p>
                              <ReactTooltip type="dark" className={styles.tooltiparea} id="user-error">
                                  <p style={{'fontSize': '1rem'}}>
                                    <FormattedMessage id="sign-error-user-text"/>
                                 </p>
                              </ReactTooltip>
                           </span>
                        </div>
                     </CSSTransition>
                     <CSSTransition in={!isPassFieldValid} timeout={500} classNames="showAnim" unmountOnExit>
                        <div className={styles.error} >
                           <p className={styles.error__text}><FormattedMessage id="sign-error-password-title"/></p>
                           <span className={styles.tooltip}>
                              <p data-for="password-error" data-tip><img src={tooltipIcon} className={styles.tooltip__icon} alt="tooltip icon" /></p>
                              <ReactTooltip type="dark" className={styles.tooltiparea} id="password-error">
                                  <p style={{'fontSize': '1rem'}}>
                                    <FormattedMessage id="sign-error-password-text"/>
                                 </p>
                              </ReactTooltip>
                           </span>
                        </div>
                     </CSSTransition>
			</form>
		</div>
	);
};

export default AdminLogin;
