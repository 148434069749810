import { useEffect, useRef, useState } from 'react';
import Container from '../UI/Container/Container';
import Loader from '../UI/Loader/Loader';
import styles from './CheckIp.module.css';
import {FormattedMessage} from 'react-intl'
import Aos from 'aos'
import CheckIpFaq from './CheckIpFaq/CheckIpFaq';
import { Helmet } from 'react-helmet';
import api from '../../api/api';
import { Link } from 'react-router-dom'


const CheckIp = props => {

   const [isLoading, setIsLoading] = useState(false)
   const [result, setResult] = useState({sync: null, site: ''})
   const [isFormValid, setIsFormValid] = useState(true)
   const [formSubmit, setFormSubmit] = useState(false)
   const refField = useRef(null)
   const refInput = useRef(null)

   useEffect(() => {
      Aos.init({duration: 1000, disable: window.innerWidth < 990})
      props.setIsHomePage(false)
   }, [])

   useEffect(() => {
      if (!formSubmit) {
         return
      }

      if (!refInput.current.value.trim()) {
         setIsFormValid(false)
         setTimeout(() => setIsFormValid(true), 820)
         return false;
      }
      if (result.sync !== null) {
         setResult(prevState => {
            return {
               ...prevState,
               sync: null
            }
         })
      }
      setIsLoading(true)

      let isSync
      let url

      url = refInput.current.value

      if (url.includes('www.')) {
         url = url.replace('www.', '')
      }
      
      if (url.includes('http://')) {
         url = url.replace('http://', '')
      }

      if (url.includes('https://')) {
         url = url.replace('https://', '')
      }

      api.checkIpv6(url).then(res => {
         if (res.status === 200) {
            isSync = true
         }
         if (res.status === 204) {
            isSync = false
         }
      }) 
      
      setTimeout(() => {
         setIsLoading(false)
         setResult({sync: isSync, site: refInput.current.value})
         refInput.current.value = ''
         setFormSubmit(false)
      }, 2500)

   }, [formSubmit])

   const classes = isLoading ? styles.loading : ''

   let outputClasses = ''
   let isSync = ''


   if (result.sync === true) {
      outputClasses = styles.good
      isSync = true
   } else if (result.sync === false) {
      outputClasses = styles.bad
      isSync = false
   }

   function submitFormHandler(e) {
      e.preventDefault()
      setFormSubmit(true)
   }

   console.log(refField.current)
	return (
		<main>
         <Helmet>
            <meta charSet="utf-8" />
            <title>Проверка поддержки IPv6 протокола сайтом - ASAproxy</title>
            <meta name="description" content="Ротация на каждый запрос, ротация по времени, отличная скорость, безлимитный трафик" />
         </Helmet>
			<section className={styles.ip}>
				<Container>
					<h1 className={styles.title} data-aos="fade-down">
						<FormattedMessage id="ipv6-title" />
					</h1>
					<p className={styles.subtitle} data-aos="fade-down">
                  <FormattedMessage id="ipv6-subtitle" />
					</p>
					<form action="#" className={styles.form} onSubmit={submitFormHandler} data-aos="fade-right">
						<div className={styles['form__input-wrapper'] + ' ' + classes + ' ' + (!isFormValid ? styles.invalid : '')}>
                     <span className={styles.form__protocol}>
                        http(s)://
                     </span>
							<input ref={refInput} disabled={isLoading}
								type="text"
								className={styles.form__input + ' ' + classes}
								placeholder="domain.com"
							/>
						</div>
						{!isLoading ? <button type="submit" className={styles.form__submit}>
                     <FormattedMessage id="ipv6-btn" />
						</button> : <Loader />}
					</form>
               <div className={styles.result__wrapper}>
                  <div className={styles.result + ' ' + outputClasses}>
                     <p ref={refField}>{isSync ? <FormattedMessage id="ipv6-result-good" values={{sitename: result.site}}/> : <FormattedMessage id="ipv6-result-bad" values={{sitename: result.site}}/>}
                     </p>
                  </div>
                  {isSync && <Link to={'/purchase'} className={styles.buy__btn}><span><FormattedMessage id='description-btn'/></span></Link>}
               </div>
				</Container>
			</section>
         <CheckIpFaq />
		</main>
	);
};

export default CheckIp;
