import { Link, useLocation } from "react-router-dom"
import * as Scroll from 'react-scroll';
var scroll = Scroll.animateScroll;

const HeaderMobLink = props => {
   const location = useLocation();
   function checkSameUrlHandler(e) {
      props.setIsHeaderOpen(false)
      if (location.pathname === props.link) {
         e.preventDefault()
         scroll.scrollToTop({duration: 500});
      }
   }
   return <li>
      <Link to={props.link} onClick={checkSameUrlHandler}>{props.name}</Link>
   </li>
}

export default HeaderMobLink