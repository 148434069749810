import styles from './KnowledgeBaseItemChildren.module.css'
import React from 'react'

const KnowledgeBaseItemChildren = React.forwardRef((props, ref) => {
   return <div className={styles.child} id={props.id} ref={ref}>
      <h3 className={styles.title}>{props.title}</h3>
      <div className={styles.body}>
         {props.body}
      </div>
   </div>
})

export default KnowledgeBaseItemChildren