import React from "react"

const array = [
   {
      ruTitle: 'Зачем нужна проверка сайта на поддержку IPv6 протокола?',
      ruBody: <p>
           Проверка выполняется для того, чтобы узнать совместимы ли IPv6 прокси с нужным вам сайтом. Если сайт поддерживает IPv6, то скорее всего с ним можно работать через IPv6 прокси.
         </p>,
      enTitle: 'Ipv6 Title 1',
      enBody: <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, incidunt! Asperiores, quaerat, doloremque totam aut consequatur sunt quis nisi quos cum obcaecati deleniti autem maiores eaque debitis natus id. Voluptatibus.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, incidunt! Asperiores, quaerat, doloremque totam aut consequatur sunt quis nisi quos cum obcaecati deleniti autem maiores eaque debitis natus id. Voluptatibus.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, incidunt! Asperiores, quaerat, doloremque totam aut consequatur sunt quis nisi quos cum obcaecati deleniti autem maiores eaque debitis natus id. Voluptatibus.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, incidunt! Asperiores, quaerat, doloremque totam aut consequatur sunt quis nisi quos cum obcaecati deleniti autem maiores eaque debitis natus id. Voluptatibus.</p>   
   },
   {
      ruTitle: 'Ваш чекер работает точно, он не ошибается?',
      ruBody: <p>
            При проверке сайта используется собственное API, которое выдает 100% верный результат, поэтому ошибки исключены.
         </p>,
      enTitle: 'Ipv6 Title 2',
      enBody: <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, incidunt! Asperiores, quaerat, doloremque totam aut consequatur sunt quis nisi quos cum obcaecati deleniti autem maiores eaque debitis natus id. Voluptatibus.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, incidunt! Asperiores, quaerat, doloremque totam aut consequatur sunt quis nisi quos cum obcaecati deleniti autem maiores eaque debitis natus id. Voluptatibus.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, incidunt! Asperiores, quaerat, doloremque totam aut consequatur sunt quis nisi quos cum obcaecati deleniti autem maiores eaque debitis natus id. Voluptatibus.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, incidunt! Asperiores, quaerat, doloremque totam aut consequatur sunt quis nisi quos cum obcaecati deleniti autem maiores eaque debitis natus id. Voluptatibus.</p>   
   },
   {
      ruTitle: 'Что делать, если сайт не поддерживает IPv6 протокол?',
      ruBody: <p>
           Попробуйте подставить перед главным доменом субдомен ipv6. Например, если изначальный сайт имеет вид speedtest.net, то после подстановки он должен выглядить так ipv6.speedtest.net. Если в таком случае сайт не открывается, значит он еще не поддерживает IPv6 и вам следует использовать IPv4 протокол. К сожалению, мы не продаем IPv4 прокси.
         </p>,
      enTitle: 'Ipv6 Title 3',
      enBody: <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, incidunt! Asperiores, quaerat, doloremque totam aut consequatur sunt quis nisi quos cum obcaecati deleniti autem maiores eaque debitis natus id. Voluptatibus.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, incidunt! Asperiores, quaerat, doloremque totam aut consequatur sunt quis nisi quos cum obcaecati deleniti autem maiores eaque debitis natus id. Voluptatibus.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, incidunt! Asperiores, quaerat, doloremque totam aut consequatur sunt quis nisi quos cum obcaecati deleniti autem maiores eaque debitis natus id. Voluptatibus.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, incidunt! Asperiores, quaerat, doloremque totam aut consequatur sunt quis nisi quos cum obcaecati deleniti autem maiores eaque debitis natus id. Voluptatibus.</p>   
   },
   {
      ruTitle: 'Ваши прокси будут работать, если сайт совместим с Ipv6?',
      ruBody: <p>
           Наши прокси будут работать с сайтом, если он поддерживает IPv6 протокол. Однако, на сайте могут быть дополнительные меры защиты в виде капчи или блокировки IP после нескольких запросов. Советуем вам это заранее проверять.
         </p>,
      enTitle: 'Ipv6 Title 4',
      enBody: <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, incidunt! Asperiores, quaerat, doloremque totam aut consequatur sunt quis nisi quos cum obcaecati deleniti autem maiores eaque debitis natus id. Voluptatibus.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, incidunt! Asperiores, quaerat, doloremque totam aut consequatur sunt quis nisi quos cum obcaecati deleniti autem maiores eaque debitis natus id. Voluptatibus.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, incidunt! Asperiores, quaerat, doloremque totam aut consequatur sunt quis nisi quos cum obcaecati deleniti autem maiores eaque debitis natus id. Voluptatibus.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, incidunt! Asperiores, quaerat, doloremque totam aut consequatur sunt quis nisi quos cum obcaecati deleniti autem maiores eaque debitis natus id. Voluptatibus.</p>   
   },
   
]

export default array;