import styles from './CheckIpFaqItem.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'

const CheckIpFaqItem = props => {

   const [isOpen, setIsOpen] = useState(false)

   const openClasses = isOpen ? styles.open : ''

   return <div className={styles.item} >
      <div className={styles.item__head} onClick={() => setIsOpen(!isOpen)}>
         <FontAwesomeIcon className={styles.item__icon + ' ' + openClasses} icon={faAngleDown} />
         <h4 className={styles.item__title}>{props.title}</h4>
      </div>
      <div className={styles.item__body + ' ' + openClasses}>
         <div className={styles.item__content}>
            {props.body}
         </div>
      </div>
   </div>
}

export default CheckIpFaqItem