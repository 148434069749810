import { useEffect, useState } from 'react'
import { Link } from 'react-scroll'
import styles from './KnowledgeBaseNavItem.module.css'

const KnowledgeBaseNavItem = props => {
   const [linkClasses, setLinkClasses] = useState(styles.link__btn)
   let classes = styles.link

   if (props.type === 'main') {
      classes += ' ' + styles.main
   }

   useEffect(() => {
      window.addEventListener('scroll', e => {
         console.log(document.documentElement.scrollTop )
         if (document.documentElement.scrollTop > props.end) {
            setLinkClasses(styles.link__btn + ' ' + styles.link__past)
         }

         if (document.documentElement.scrollTop < props.start) {
            setLinkClasses(styles.link__btn)
         }

         if (props.end > document.documentElement.scrollTop && document.documentElement.scrollTop > props.start) {
            setLinkClasses(styles.link__btn + ' ' + styles.link__active)
         }
      })
   }, [linkClasses])

   const withChildClasses = props.childList ? styles.with__child : ''

   return <div className={styles.link} >
      <Link className={linkClasses + ' ' + (props.type === 'main' ? styles.main : '')} to={props.id} spy={true} smooth={true} offset={-130} duration={500} >{props.title}</Link>
      {props.childList ? props.childList : ''}
   </div>
}

export default KnowledgeBaseNavItem