import { Link } from "react-router-dom"

const HeaderMobAnchorLinkWithReload = props => {

   const clickHandler = () => {
      props.setIsHeaderOpen(false)
      props.setLoadHomeWithAnchor(props.link)
   }

   return <li>
      <Link to={'/'} onClick={clickHandler}>{props.name}</Link>
   </li>
}

export default HeaderMobAnchorLinkWithReload;