const firstBlockText = {
   ruTitle: 'ASAproxy',
   enTitle: 'ASAproxy',
   ruBody: <>
      <p>
         Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut voluptatem incidunt pariatur aperiam, debitis, alias odio, enim error deleniti odit reprehenderit illo tenetur assumenda! Animi id alias eum ratione laudantium.
      </p>
      <ul>
         <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</li>
         <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</li>
         <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</li>
         <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</li>
         <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</li>
      </ul>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facilis, qui quis eos pariatur aliquam fugiat necessitatibus aut. Soluta est ratione aliquam qui alias hic quas, reprehenderit exercitationem nulla, ad iste. <a href="#">Link example</a></p>
   </>,
   enBody: <>
   <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut voluptatem incidunt pariatur aperiam, debitis, alias odio, enim error deleniti odit reprehenderit illo tenetur assumenda! Animi id alias eum ratione laudantium.
   </p>
   <ul>
      <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</li>
      <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</li>
      <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</li>
      <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</li>
      <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</li>
   </ul>
   <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facilis, qui quis eos pariatur aliquam fugiat necessitatibus aut. Soluta est ratione aliquam qui alias hic quas, reprehenderit exercitationem nulla, ad iste. <a href="#">Link example</a></p>
</>,
}


const knowledgeBase = [
	{
		ruTitle: 'Install',
		enTitle: 'Install',
		ruBody: (
			<>
				<p>
					Reproxy distributed as a small self-contained binary as well as a
					docker image. Both binary and image support multiple
					architectures and multiple operating systems, including
					linux_x86_64, linux_arm64, linux_arm, macos_x86_64, macos_arm64,
					windows_x86_64 and windows_arm. We also provide both arm64 and
					x86 deb and rpm packages.
				</p>
				<ul>
					<li>
						for a binary distribution download the proper file in the
                  <a href="https://github.com/umputunreproxyreleases">release section</a>
					</li>
					<li>
						docker container available on
						<a href="https://hub.docker.com/r/umputun/reproxy">
							Docker Hub
						</a>
						as well as on
						<a href="https://github.com/users/umputun/packages/container/reproxy/versions">
							Github Container Registry
						</a>
						. I.e. <code>docker pull umputun/reproxy</code> or
						<code>docker pull ghcr.io/umputun/reproxy</code>.
					</li>
				</ul>
				<p>
					Latest stable version has <code>:vX.Y.Z</code> docker tag (with
					<code>:latest</code> alias) and the current master has
					<code>:master</code> tag.
				</p>
			</>
		),
		enBody: (
			<>
				<p>
					Reproxy distributed as a small self-contained binary as well as a
					docker image. Both binary and image support multiple
					architectures and multiple operating systems, including
					linux_x86_64, linux_arm64, linux_arm, macos_x86_64, macos_arm64,
					windows_x86_64 and windows_arm. We also provide both arm64 and
					x86 deb and rpm packages.
				</p>
				<ul>
					<li>
						for a binary distribution download the proper file in the <a href="https://github.com/umputun/reproxy/releases">release section</a>
					</li>
					<li>
						docker container available on <a href="https://hub.docker.com/r/umputun/reproxy">Docker Hub</a> as well as on <a href="https://github.com/users/umputun/packages/container/reproxy/versions">Github Container Registry</a>. I.e. <code>docker pull umputun/reproxy</code> or <code>docker pull ghcr.io/umputun/reproxy</code>.
					</li>
				</ul>
				<p>
					Latest stable version has <code>:vX.Y.Z</code> docker tag (with
					<code>:latest</code> alias) and the current master has
					<code>:master</code> tag.
				</p>
			</>
		),
	},
	{
		ruTitle: 'Providers',
		enTitle: 'Providers',
		ruBody: (
			<>
				<p>
					Proxy rules supplied by various providers. Currently included -
					<code>file</code>, <code>docker</code>, <code>static</code> and
					<code>consul-catalog</code>. Each provider may define multiple
					routing rules for both proxied request and static (assets). User
					can sets multiple providers at the same time.
				</p>
				<p>
					<em>
						See examples of various providers in
						<a href="https://github.com/umputun/reproxy/tree/master/examples">
							examples
						</a>
					</em>
				</p>
			</>
		),
		enBody: (
			<>
				<p>
					Proxy rules supplied by various providers. Currently included -
					<code>file</code>, <code>docker</code>, <code>static</code> and
					<code>consul-catalog</code>. Each provider may define multiple
					routing rules for both proxied request and static (assets). User
					can sets multiple providers at the same time.
				</p>
				<p>
					<em>
						See examples of various providers in
						<a href="https://github.com/umputun/reproxy/tree/master/examples">
							examples
						</a>
					</em>
				</p>
			</>
		),
		children: [
			{
				ruTitle: 'Static provider',
				enTitle: 'Static provider',
				ruBody: (
					<>
						<p>
							This is the simplest provider defining all mapping rules
							directly in the command line (or environment). Multiple
							rules supported. Each rule is 3 or 4 comma-separated
							elements
							<code>server,sourceurl,destination[,ping-url]</code>. For
							example:
						</p>
						<ul>
							<li>
								<code>*,^/api/(.*),https://api.example.com/$1</code> -
								proxy all request to any host/server with
								<code>/api</code> prefix to
								<code>https://api.example.com</code>
							</li>
							<li>
								<code>
									example.com,/foo/bar,https://api.example.com/zzz,https://api.example.com/ping
								</code>
								- proxy all requests to <code>example.com</code> and
								with <code>/foo/bar</code> url to
								<code>https://api.example.com/zzz</code> and it sses
								<code>https://api.example.com/ping</code> for the health
								check.
							</li>
						</ul>
						<p>
							The last (4<sup>th</sup>) element defines an optional ping
							url used for health reporting. I.e.
							<code>
								*,^/api/(.*),https://api.example.com/$1,https://api.example.com/ping
							</code>
							. See <a href="#ping-and-health-checks">Health check</a>
							section for more details.
						</p>
					</>
				),
				enBody: (
					<>
						<p>
							This is the simplest provider defining all mapping rules
							directly in the command line (or environment). Multiple
							rules supported. Each rule is 3 or 4 comma-separated
							elements
							<code>server,sourceurl,destination[,ping-url]</code>. For
							example:
						</p>
						<ul>
							<li>
								<code>*,^/api/(.*),https://api.example.com/$1</code> -
								proxy all request to any host/server with
								<code>/api</code> prefix to
								<code>https://api.example.com</code>
							</li>
							<li>
								<code>
									example.com,/foo/bar,https://api.example.com/zzz,https://api.example.com/ping
								</code>
								- proxy all requests to <code>example.com</code> and
								with <code>/foo/bar</code> url to
								<code>https://api.example.com/zzz</code> and it sses
								<code>https://api.example.com/ping</code> for the health
								check.
							</li>
						</ul>
						<p>
							The last (4<sup>th</sup>) element defines an optional ping
							url used for health reporting. I.e.
							<code>
								*,^/api/(.*),https://api.example.com/$1,https://api.example.com/ping
							</code>
							. See <a href="#ping-and-health-checks">Health check</a>
							section for more details.
						</p>
					</>
				),
			},
			{
				ruTitle: 'File provider',
				enTitle: 'File provider',
				ruBody: (
					<>
						<p>This provider uses yaml file with routing rules.</p>
					</>
				),
				enBody: (
					<>
						<p>This provider uses yaml file with routing rules.</p>
					</>
				),
			},
			{
				ruTitle: 'Docker provider',
				enTitle: 'Docker provider',
				ruBody: (
					<>
						<p>
							Docker provider supports a fully automatic discovery (with
							<code>--docker.auto</code>) with no extra configuration
							needed. By default, it redirects all requests like
							<code>http://&lt;url&gt;/&lt;container name&gt;/(.*)</code>
							to the internal IP of the given container and the exposed
							port. Only active (running) containers will be detected.
						</p>
						<p>This default can be changed with labels:</p>
						<ul>
							<li>
								<code>reproxy.server</code> - server (hostname) to
								match. Also can be a list of comma-separated servers.
							</li>
							<li>
								<code>reproxy.route</code> - source route (location)
							</li>
							<li>
								<code>reproxy.dest</code> - destination path. Note: this
								is not full url, but just the path which will be
								appended to container’s ip:port
							</li>
							<li>
								<code>reproxy.port</code> - destination port for the
								discovered container
							</li>
							<li>
								<code>reproxy.ping</code> - ping path for the
								destination container.
							</li>
							<li>
								<code>reproxy.assets</code> - set assets mapping as
								<code>web-root:location</code>, for example
								<code>reproxy.assets=/web:/var/www</code>
							</li>
							<li>
								<code>reproxy.enabled</code> - enable (<code>yes</code>,
								<code>true</code>, <code>1</code>) or disable (
								<code>no</code>, <code>false</code>, <code>0</code>)
								container from reproxy destinations.
							</li>
						</ul>
						<p>
							Pls note: without <code>--docker.auto</code> the
							destination container has to have at least one of
							<code>reproxy.*</code> labels to be considered as a
							potential destination.
						</p>
						<p>
							With <code>--docker.auto</code>, all containers with
							exposed port will be considered as routing destinations.
							There are 3 ways to restrict it:
						</p>
						<ul>
							<li>
								Exclude some containers explicitly with
								<code>--docker.exclude</code>, i.e.
								<code>--docker.exclude=c1 --docker.exclude=c2 ...</code>
							</li>
							<li>
								Allow only a particular docker network with
								<code>--docker.network</code>
							</li>
							<li>
								Set the label <code>reproxy.enabled=false</code> or
								<code>reproxy.enabled=no</code> or
								<code>reproxy.enabled=0</code>
							</li>
						</ul>
					</>
				),
				enBody: (
					<>
						<p>This provider uses yaml file with routing rules.</p>
					</>
				),
			},
		],
	},
   {
      ruTitle: 'SSL support',
      enTitle: 'SSL support',
      ruBody: (
         <>
            <p>
               SSL mode (by default none) can be set to <code>auto</code>{' '}
               (ACME/LE certificates), <code>static</code> (existing
               certificate) or <code>none</code>. If <code>auto</code>{' '}
               turned on SSL certificate will be issued automatically for
               all discovered server names. User can override it by
               setting <code>--ssl.fqdn</code> value(s)
            </p>
            <p>
               Reproxy allows to sanitize (remove) incoming headers by
               passing <code>--drop-header</code> parameter (can be
               repeated). This parameter can be useful to make sure some
               of the headers, set internally by the services, can’t be
               set/faked by the end user. For example if some of the
               services, responsible for the auth, sets{' '}
               <code>X-Auth-User</code> and <code>X-Auth-Token</code> it
               is likely makes sense to drop those headers from the
               incoming requests by passing{' '}
               <code>
                  --drop-header=X-Auth-User --drop-header=X-Auth-Token
               </code>{' '}
               parameter or via environment{' '}
               <code>DROP_HEADERS=X-Auth-User,X-Auth-Token</code>
            </p>
            <p>
               The opposite function, setting outgoing header(s) supported
               as well. It can be useful in many cases, for example
               enforcing some custom CORS rules, security related headers
               and so on. This can be done with <code>--header</code>{' '}
               parameter (can be repeated) or env <code>HEADER</code>. For
               example, this is how it can be done with the docker
               compose:
            </p>
            <p>Users may turn the assets server on (off by default) to serve static files. As long as <code>--assets.location</code> set it treats every non-proxied request under <code>assets.root</code> as a request for static files. The assets server can be used without any proxy providers; in this mode, reproxy acts as a simple web server for the static content. Assets server also supports “spa mode” with <code>--assets.spa</code> where all not-found request forwarded to <code>index.html</code>.</p>
            <p>In addition to the common assets server, multiple custom assets servers are supported. Each provider has a different way to define such a static rule, and some providers may not support it at all. For example, multiple asset servers make sense in static (command line provider), file provider, and even useful with docker providers, however it makes very little sense with consul catalog provider.</p>
         </>
      ),
      enBody: (
         <>
            <p>
               SSL mode (by default none) can be set to <code>auto</code>{' '}
               (ACME/LE certificates), <code>static</code> (existing
               certificate) or <code>none</code>. If <code>auto</code>{' '}
               turned on SSL certificate will be issued automatically for
               all discovered server names. User can override it by
               setting <code>--ssl.fqdn</code> value(s)
            </p>
            <p>
               Reproxy allows to sanitize (remove) incoming headers by
               passing <code>--drop-header</code> parameter (can be
               repeated). This parameter can be useful to make sure some
               of the headers, set internally by the services, can’t be
               set/faked by the end user. For example if some of the
               services, responsible for the auth, sets{' '}
               <code>X-Auth-User</code> and <code>X-Auth-Token</code> it
               is likely makes sense to drop those headers from the
               incoming requests by passing{' '}
               <code>
                  --drop-header=X-Auth-User --drop-header=X-Auth-Token
               </code>{' '}
               parameter or via environment{' '}
               <code>DROP_HEADERS=X-Auth-User,X-Auth-Token</code>
            </p>
            <p>
               The opposite function, setting outgoing header(s) supported
               as well. It can be useful in many cases, for example
               enforcing some custom CORS rules, security related headers
               and so on. This can be done with <code>--header</code>{' '}
               parameter (can be repeated) or env <code>HEADER</code>. For
               example, this is how it can be done with the docker
               compose:
            </p>
            <p>Users may turn the assets server on (off by default) to serve static files. As long as <code>--assets.location</code> set it treats every non-proxied request under <code>assets.root</code> as a request for static files. The assets server can be used without any proxy providers; in this mode, reproxy acts as a simple web server for the static content. Assets server also supports “spa mode” with <code>--assets.spa</code> where all not-found request forwarded to <code>index.html</code>.</p>
            <p>In addition to the common assets server, multiple custom assets servers are supported. Each provider has a different way to define such a static rule, and some providers may not support it at all. For example, multiple asset servers make sense in static (command line provider), file provider, and even useful with docker providers, however it makes very little sense with consul catalog provider.</p>
         </>
      ),
   },
   // {
	// 	ruTitle: 'Install1',
	// 	enTitle: 'Install1',
	// 	ruBody: (
	// 		<>
	// 			<p>
	// 				Reproxy distributed as a small self-contained binary as well as a
	// 				docker image. Both binary and image support multiple
	// 				architectures and multiple operating systems, including
	// 				linux_x86_64, linux_arm64, linux_arm, macos_x86_64, macos_arm64,
	// 				windows_x86_64 and windows_arm. We also provide both arm64 and
	// 				x86 deb and rpm packages.
	// 			</p>
	// 			<ul>
	// 				<li>
	// 					for a binary distribution download the proper file in the
   //                <a href="https://github.com/umputunreproxyreleases">release section</a>
	// 				</li>
	// 				<li>
	// 					docker container available on
	// 					<a href="https://hub.docker.com/r/umputun/reproxy">
	// 						Docker Hub
	// 					</a>
	// 					as well as on
	// 					<a href="https://github.com/users/umputun/packages/container/reproxy/versions">
	// 						Github Container Registry
	// 					</a>
	// 					. I.e. <code>docker pull umputun/reproxy</code> or
	// 					<code>docker pull ghcr.io/umputun/reproxy</code>.
	// 				</li>
	// 			</ul>
	// 			<p>
	// 				Latest stable version has <code>:vX.Y.Z</code> docker tag (with
	// 				<code>:latest</code> alias) and the current master has
	// 				<code>:master</code> tag.
	// 			</p>
	// 		</>
	// 	),
	// 	enBody: (
	// 		<>
	// 			<p>
	// 				Reproxy distributed as a small self-contained binary as well as a
	// 				docker image. Both binary and image support multiple
	// 				architectures and multiple operating systems, including
	// 				linux_x86_64, linux_arm64, linux_arm, macos_x86_64, macos_arm64,
	// 				windows_x86_64 and windows_arm. We also provide both arm64 and
	// 				x86 deb and rpm packages.
	// 			</p>
	// 			<ul>
	// 				<li>
	// 					for a binary distribution download the proper file in the <a href="https://github.com/umputun/reproxy/releases">release section</a>
	// 				</li>
	// 				<li>
	// 					docker container available on <a href="https://hub.docker.com/r/umputun/reproxy">Docker Hub</a> as well as on <a href="https://github.com/users/umputun/packages/container/reproxy/versions">Github Container Registry</a>. I.e. <code>docker pull umputun/reproxy</code> or <code>docker pull ghcr.io/umputun/reproxy</code>.
	// 				</li>
	// 			</ul>
	// 			<p>
	// 				Latest stable version has <code>:vX.Y.Z</code> docker tag (with
	// 				<code>:latest</code> alias) and the current master has
	// 				<code>:master</code> tag.
	// 			</p>
	// 		</>
	// 	),
	// },
	// {
	// 	ruTitle: 'Providers1',
	// 	enTitle: 'Providers1',
	// 	ruBody: (
	// 		<>
	// 			<p>
	// 				Proxy rules supplied by various providers. Currently included -
	// 				<code>file</code>, <code>docker</code>, <code>static</code> and
	// 				<code>consul-catalog</code>. Each provider may define multiple
	// 				routing rules for both proxied request and static (assets). User
	// 				can sets multiple providers at the same time.
	// 			</p>
	// 			<p>
	// 				<em>
	// 					See examples of various providers in
	// 					<a href="https://github.com/umputun/reproxy/tree/master/examples">
	// 						examples
	// 					</a>
	// 				</em>
	// 			</p>
	// 		</>
	// 	),
	// 	enBody: (
	// 		<>
	// 			<p>
	// 				Proxy rules supplied by various providers. Currently included -
	// 				<code>file</code>, <code>docker</code>, <code>static</code> and
	// 				<code>consul-catalog</code>. Each provider may define multiple
	// 				routing rules for both proxied request and static (assets). User
	// 				can sets multiple providers at the same time.
	// 			</p>
	// 			<p>
	// 				<em>
	// 					See examples of various providers in
	// 					<a href="https://github.com/umputun/reproxy/tree/master/examples">
	// 						examples
	// 					</a>
	// 				</em>
	// 			</p>
	// 		</>
	// 	),
	// 	children: [
	// 		{
	// 			ruTitle: 'Static provider1',
	// 			enTitle: 'Static provider1',
	// 			ruBody: (
	// 				<>
	// 					<p>
	// 						This is the simplest provider defining all mapping rules
	// 						directly in the command line (or environment). Multiple
	// 						rules supported. Each rule is 3 or 4 comma-separated
	// 						elements
	// 						<code>server,sourceurl,destination[,ping-url]</code>. For
	// 						example:
	// 					</p>
	// 					<ul>
	// 						<li>
	// 							<code>*,^/api/(.*),https://api.example.com/$1</code> -
	// 							proxy all request to any host/server with
	// 							<code>/api</code> prefix to
	// 							<code>https://api.example.com</code>
	// 						</li>
	// 						<li>
	// 							<code>
	// 								example.com,/foo/bar,https://api.example.com/zzz,https://api.example.com/ping
	// 							</code>
	// 							- proxy all requests to <code>example.com</code> and
	// 							with <code>/foo/bar</code> url to
	// 							<code>https://api.example.com/zzz</code> and it sses
	// 							<code>https://api.example.com/ping</code> for the health
	// 							check.
	// 						</li>
	// 					</ul>
	// 					<p>
	// 						The last (4<sup>th</sup>) element defines an optional ping
	// 						url used for health reporting. I.e.
	// 						<code>
	// 							*,^/api/(.*),https://api.example.com/$1,https://api.example.com/ping
	// 						</code>
	// 						. See <a href="#ping-and-health-checks">Health check</a>
	// 						section for more details.
	// 					</p>
	// 				</>
	// 			),
	// 			enBody: (
	// 				<>
	// 					<p>
	// 						This is the simplest provider defining all mapping rules
	// 						directly in the command line (or environment). Multiple
	// 						rules supported. Each rule is 3 or 4 comma-separated
	// 						elements
	// 						<code>server,sourceurl,destination[,ping-url]</code>. For
	// 						example:
	// 					</p>
	// 					<ul>
	// 						<li>
	// 							<code>*,^/api/(.*),https://api.example.com/$1</code> -
	// 							proxy all request to any host/server with
	// 							<code>/api</code> prefix to
	// 							<code>https://api.example.com</code>
	// 						</li>
	// 						<li>
	// 							<code>
	// 								example.com,/foo/bar,https://api.example.com/zzz,https://api.example.com/ping
	// 							</code>
	// 							- proxy all requests to <code>example.com</code> and
	// 							with <code>/foo/bar</code> url to
	// 							<code>https://api.example.com/zzz</code> and it sses
	// 							<code>https://api.example.com/ping</code> for the health
	// 							check.
	// 						</li>
	// 					</ul>
	// 					<p>
	// 						The last (4<sup>th</sup>) element defines an optional ping
	// 						url used for health reporting. I.e.
	// 						<code>
	// 							*,^/api/(.*),https://api.example.com/$1,https://api.example.com/ping
	// 						</code>
	// 						. See <a href="#ping-and-health-checks">Health check</a>
	// 						section for more details.
	// 					</p>
	// 				</>
	// 			),
	// 		},
	// 		{
	// 			ruTitle: 'File provider1',
	// 			enTitle: 'File provider1',
	// 			ruBody: (
	// 				<>
	// 					<p>This provider uses yaml file with routing rules.</p>
	// 				</>
	// 			),
	// 			enBody: (
	// 				<>
	// 					<p>This provider uses yaml file with routing rules.</p>
	// 				</>
	// 			),
	// 		},
	// 		{
	// 			ruTitle: 'Docker provider1',
	// 			enTitle: 'Docker provider1',
	// 			ruBody: (
	// 				<>
	// 					<p>
	// 						Docker provider supports a fully automatic discovery (with
	// 						<code>--docker.auto</code>) with no extra configuration
	// 						needed. By default, it redirects all requests like
	// 						<code>http://&lt;url&gt;/&lt;container name&gt;/(.*)</code>
	// 						to the internal IP of the given container and the exposed
	// 						port. Only active (running) containers will be detected.
	// 					</p>
	// 					<p>This default can be changed with labels:</p>
	// 					<ul>
	// 						<li>
	// 							<code>reproxy.server</code> - server (hostname) to
	// 							match. Also can be a list of comma-separated servers.
	// 						</li>
	// 						<li>
	// 							<code>reproxy.route</code> - source route (location)
	// 						</li>
	// 						<li>
	// 							<code>reproxy.dest</code> - destination path. Note: this
	// 							is not full url, but just the path which will be
	// 							appended to container’s ip:port
	// 						</li>
	// 						<li>
	// 							<code>reproxy.port</code> - destination port for the
	// 							discovered container
	// 						</li>
	// 						<li>
	// 							<code>reproxy.ping</code> - ping path for the
	// 							destination container.
	// 						</li>
	// 						<li>
	// 							<code>reproxy.assets</code> - set assets mapping as
	// 							<code>web-root:location</code>, for example
	// 							<code>reproxy.assets=/web:/var/www</code>
	// 						</li>
	// 						<li>
	// 							<code>reproxy.enabled</code> - enable (<code>yes</code>,
	// 							<code>true</code>, <code>1</code>) or disable (
	// 							<code>no</code>, <code>false</code>, <code>0</code>)
	// 							container from reproxy destinations.
	// 						</li>
	// 					</ul>
	// 					<p>
	// 						Pls note: without <code>--docker.auto</code> the
	// 						destination container has to have at least one of
	// 						<code>reproxy.*</code> labels to be considered as a
	// 						potential destination.
	// 					</p>
	// 					<p>
	// 						With <code>--docker.auto</code>, all containers with
	// 						exposed port will be considered as routing destinations.
	// 						There are 3 ways to restrict it:
	// 					</p>
	// 					<ul>
	// 						<li>
	// 							Exclude some containers explicitly with
	// 							<code>--docker.exclude</code>, i.e.
	// 							<code>--docker.exclude=c1 --docker.exclude=c2 ...</code>
	// 						</li>
	// 						<li>
	// 							Allow only a particular docker network with
	// 							<code>--docker.network</code>
	// 						</li>
	// 						<li>
	// 							Set the label <code>reproxy.enabled=false</code> or
	// 							<code>reproxy.enabled=no</code> or
	// 							<code>reproxy.enabled=0</code>
	// 						</li>
	// 					</ul>
	// 				</>
	// 			),
	// 			enBody: (
	// 				<>
	// 					<p>This provider uses yaml file with routing rules.</p>
	// 				</>
	// 			),
	// 		},
	// 	],
	// },
   // {
   //    ruTitle: 'SSL support1',
   //    enTitle: 'SSL support1',
   //    ruBody: (
   //       <>
   //          <p>
   //             SSL mode (by default none) can be set to <code>auto</code>{' '}
   //             (ACME/LE certificates), <code>static</code> (existing
   //             certificate) or <code>none</code>. If <code>auto</code>{' '}
   //             turned on SSL certificate will be issued automatically for
   //             all discovered server names. User can override it by
   //             setting <code>--ssl.fqdn</code> value(s)
   //          </p>
   //          <p>
   //             Reproxy allows to sanitize (remove) incoming headers by
   //             passing <code>--drop-header</code> parameter (can be
   //             repeated). This parameter can be useful to make sure some
   //             of the headers, set internally by the services, can’t be
   //             set/faked by the end user. For example if some of the
   //             services, responsible for the auth, sets{' '}
   //             <code>X-Auth-User</code> and <code>X-Auth-Token</code> it
   //             is likely makes sense to drop those headers from the
   //             incoming requests by passing{' '}
   //             <code>
   //                --drop-header=X-Auth-User --drop-header=X-Auth-Token
   //             </code>{' '}
   //             parameter or via environment{' '}
   //             <code>DROP_HEADERS=X-Auth-User,X-Auth-Token</code>
   //          </p>
   //          <p>
   //             The opposite function, setting outgoing header(s) supported
   //             as well. It can be useful in many cases, for example
   //             enforcing some custom CORS rules, security related headers
   //             and so on. This can be done with <code>--header</code>{' '}
   //             parameter (can be repeated) or env <code>HEADER</code>. For
   //             example, this is how it can be done with the docker
   //             compose:
   //          </p>
   //          <p>Users may turn the assets server on (off by default) to serve static files. As long as <code>--assets.location</code> set it treats every non-proxied request under <code>assets.root</code> as a request for static files. The assets server can be used without any proxy providers; in this mode, reproxy acts as a simple web server for the static content. Assets server also supports “spa mode” with <code>--assets.spa</code> where all not-found request forwarded to <code>index.html</code>.</p>
   //          <p>In addition to the common assets server, multiple custom assets servers are supported. Each provider has a different way to define such a static rule, and some providers may not support it at all. For example, multiple asset servers make sense in static (command line provider), file provider, and even useful with docker providers, however it makes very little sense with consul catalog provider.</p>
   //       </>
   //    ),
   //    enBody: (
   //       <>
   //          <p>
   //             SSL mode (by default none) can be set to <code>auto</code>{' '}
   //             (ACME/LE certificates), <code>static</code> (existing
   //             certificate) or <code>none</code>. If <code>auto</code>{' '}
   //             turned on SSL certificate will be issued automatically for
   //             all discovered server names. User can override it by
   //             setting <code>--ssl.fqdn</code> value(s)
   //          </p>
   //          <p>
   //             Reproxy allows to sanitize (remove) incoming headers by
   //             passing <code>--drop-header</code> parameter (can be
   //             repeated). This parameter can be useful to make sure some
   //             of the headers, set internally by the services, can’t be
   //             set/faked by the end user. For example if some of the
   //             services, responsible for the auth, sets{' '}
   //             <code>X-Auth-User</code> and <code>X-Auth-Token</code> it
   //             is likely makes sense to drop those headers from the
   //             incoming requests by passing{' '}
   //             <code>
   //                --drop-header=X-Auth-User --drop-header=X-Auth-Token
   //             </code>{' '}
   //             parameter or via environment{' '}
   //             <code>DROP_HEADERS=X-Auth-User,X-Auth-Token</code>
   //          </p>
   //          <p>
   //             The opposite function, setting outgoing header(s) supported
   //             as well. It can be useful in many cases, for example
   //             enforcing some custom CORS rules, security related headers
   //             and so on. This can be done with <code>--header</code>{' '}
   //             parameter (can be repeated) or env <code>HEADER</code>. For
   //             example, this is how it can be done with the docker
   //             compose:
   //          </p>
   //          <p>Users may turn the assets server on (off by default) to serve static files. As long as <code>--assets.location</code> set it treats every non-proxied request under <code>assets.root</code> as a request for static files. The assets server can be used without any proxy providers; in this mode, reproxy acts as a simple web server for the static content. Assets server also supports “spa mode” with <code>--assets.spa</code> where all not-found request forwarded to <code>index.html</code>.</p>
   //          <p>In addition to the common assets server, multiple custom assets servers are supported. Each provider has a different way to define such a static rule, and some providers may not support it at all. For example, multiple asset servers make sense in static (command line provider), file provider, and even useful with docker providers, however it makes very little sense with consul catalog provider.</p>
   //       </>
   //    ),
   // },
   // {
	// 	ruTitle: 'Install2',
	// 	enTitle: 'Install2',
	// 	ruBody: (
	// 		<>
	// 			<p>
	// 				Reproxy distributed as a small self-contained binary as well as a
	// 				docker image. Both binary and image support multiple
	// 				architectures and multiple operating systems, including
	// 				linux_x86_64, linux_arm64, linux_arm, macos_x86_64, macos_arm64,
	// 				windows_x86_64 and windows_arm. We also provide both arm64 and
	// 				x86 deb and rpm packages.
	// 			</p>
	// 			<ul>
	// 				<li>
	// 					for a binary distribution download the proper file in the
   //                <a href="https://github.com/umputunreproxyreleases">release section</a>
	// 				</li>
	// 				<li>
	// 					docker container available on
	// 					<a href="https://hub.docker.com/r/umputun/reproxy">
	// 						Docker Hub
	// 					</a>
	// 					as well as on
	// 					<a href="https://github.com/users/umputun/packages/container/reproxy/versions">
	// 						Github Container Registry
	// 					</a>
	// 					. I.e. <code>docker pull umputun/reproxy</code> or
	// 					<code>docker pull ghcr.io/umputun/reproxy</code>.
	// 				</li>
	// 			</ul>
	// 			<p>
	// 				Latest stable version has <code>:vX.Y.Z</code> docker tag (with
	// 				<code>:latest</code> alias) and the current master has
	// 				<code>:master</code> tag.
	// 			</p>
	// 		</>
	// 	),
	// 	enBody: (
	// 		<>
	// 			<p>
	// 				Reproxy distributed as a small self-contained binary as well as a
	// 				docker image. Both binary and image support multiple
	// 				architectures and multiple operating systems, including
	// 				linux_x86_64, linux_arm64, linux_arm, macos_x86_64, macos_arm64,
	// 				windows_x86_64 and windows_arm. We also provide both arm64 and
	// 				x86 deb and rpm packages.
	// 			</p>
	// 			<ul>
	// 				<li>
	// 					for a binary distribution download the proper file in the <a href="https://github.com/umputun/reproxy/releases">release section</a>
	// 				</li>
	// 				<li>
	// 					docker container available on <a href="https://hub.docker.com/r/umputun/reproxy">Docker Hub</a> as well as on <a href="https://github.com/users/umputun/packages/container/reproxy/versions">Github Container Registry</a>. I.e. <code>docker pull umputun/reproxy</code> or <code>docker pull ghcr.io/umputun/reproxy</code>.
	// 				</li>
	// 			</ul>
	// 			<p>
	// 				Latest stable version has <code>:vX.Y.Z</code> docker tag (with
	// 				<code>:latest</code> alias) and the current master has
	// 				<code>:master</code> tag.
	// 			</p>
	// 		</>
	// 	),
	// },
	// {
	// 	ruTitle: 'Providers2',
	// 	enTitle: 'Providers2',
	// 	ruBody: (
	// 		<>
	// 			<p>
	// 				Proxy rules supplied by various providers. Currently included -
	// 				<code>file</code>, <code>docker</code>, <code>static</code> and
	// 				<code>consul-catalog</code>. Each provider may define multiple
	// 				routing rules for both proxied request and static (assets). User
	// 				can sets multiple providers at the same time.
	// 			</p>
	// 			<p>
	// 				<em>
	// 					See examples of various providers in
	// 					<a href="https://github.com/umputun/reproxy/tree/master/examples">
	// 						examples
	// 					</a>
	// 				</em>
	// 			</p>
	// 		</>
	// 	),
	// 	enBody: (
	// 		<>
	// 			<p>
	// 				Proxy rules supplied by various providers. Currently included -
	// 				<code>file</code>, <code>docker</code>, <code>static</code> and
	// 				<code>consul-catalog</code>. Each provider may define multiple
	// 				routing rules for both proxied request and static (assets). User
	// 				can sets multiple providers at the same time.
	// 			</p>
	// 			<p>
	// 				<em>
	// 					See examples of various providers in
	// 					<a href="https://github.com/umputun/reproxy/tree/master/examples">
	// 						examples
	// 					</a>
	// 				</em>
	// 			</p>
	// 		</>
	// 	),
	// 	children: [
	// 		{
	// 			ruTitle: 'Static provider2',
	// 			enTitle: 'Static provider2',
	// 			ruBody: (
	// 				<>
	// 					<p>
	// 						This is the simplest provider defining all mapping rules
	// 						directly in the command line (or environment). Multiple
	// 						rules supported. Each rule is 3 or 4 comma-separated
	// 						elements
	// 						<code>server,sourceurl,destination[,ping-url]</code>. For
	// 						example:
	// 					</p>
	// 					<ul>
	// 						<li>
	// 							<code>*,^/api/(.*),https://api.example.com/$1</code> -
	// 							proxy all request to any host/server with
	// 							<code>/api</code> prefix to
	// 							<code>https://api.example.com</code>
	// 						</li>
	// 						<li>
	// 							<code>
	// 								example.com,/foo/bar,https://api.example.com/zzz,https://api.example.com/ping
	// 							</code>
	// 							- proxy all requests to <code>example.com</code> and
	// 							with <code>/foo/bar</code> url to
	// 							<code>https://api.example.com/zzz</code> and it sses
	// 							<code>https://api.example.com/ping</code> for the health
	// 							check.
	// 						</li>
	// 					</ul>
	// 					<p>
	// 						The last (4<sup>th</sup>) element defines an optional ping
	// 						url used for health reporting. I.e.
	// 						<code>
	// 							*,^/api/(.*),https://api.example.com/$1,https://api.example.com/ping
	// 						</code>
	// 						. See <a href="#ping-and-health-checks">Health check</a>
	// 						section for more details.
	// 					</p>
	// 				</>
	// 			),
	// 			enBody: (
	// 				<>
	// 					<p>
	// 						This is the simplest provider defining all mapping rules
	// 						directly in the command line (or environment). Multiple
	// 						rules supported. Each rule is 3 or 4 comma-separated
	// 						elements
	// 						<code>server,sourceurl,destination[,ping-url]</code>. For
	// 						example:
	// 					</p>
	// 					<ul>
	// 						<li>
	// 							<code>*,^/api/(.*),https://api.example.com/$1</code> -
	// 							proxy all request to any host/server with
	// 							<code>/api</code> prefix to
	// 							<code>https://api.example.com</code>
	// 						</li>
	// 						<li>
	// 							<code>
	// 								example.com,/foo/bar,https://api.example.com/zzz,https://api.example.com/ping
	// 							</code>
	// 							- proxy all requests to <code>example.com</code> and
	// 							with <code>/foo/bar</code> url to
	// 							<code>https://api.example.com/zzz</code> and it sses
	// 							<code>https://api.example.com/ping</code> for the health
	// 							check.
	// 						</li>
	// 					</ul>
	// 					<p>
	// 						The last (4<sup>th</sup>) element defines an optional ping
	// 						url used for health reporting. I.e.
	// 						<code>
	// 							*,^/api/(.*),https://api.example.com/$1,https://api.example.com/ping
	// 						</code>
	// 						. See <a href="#ping-and-health-checks">Health check</a>
	// 						section for more details.
	// 					</p>
	// 				</>
	// 			),
	// 		},
	// 		{
	// 			ruTitle: 'File provider2',
	// 			enTitle: 'File provider2',
	// 			ruBody: (
	// 				<>
	// 					<p>This provider uses yaml file with routing rules.</p>
	// 				</>
	// 			),
	// 			enBody: (
	// 				<>
	// 					<p>This provider uses yaml file with routing rules.</p>
	// 				</>
	// 			),
	// 		},
	// 		{
	// 			ruTitle: 'Docker provider2',
	// 			enTitle: 'Docker provider2',
	// 			ruBody: (
	// 				<>
	// 					<p>
	// 						Docker provider supports a fully automatic discovery (with
	// 						<code>--docker.auto</code>) with no extra configuration
	// 						needed. By default, it redirects all requests like
	// 						<code>http://&lt;url&gt;/&lt;container name&gt;/(.*)</code>
	// 						to the internal IP of the given container and the exposed
	// 						port. Only active (running) containers will be detected.
	// 					</p>
	// 					<p>This default can be changed with labels:</p>
	// 					<ul>
	// 						<li>
	// 							<code>reproxy.server</code> - server (hostname) to
	// 							match. Also can be a list of comma-separated servers.
	// 						</li>
	// 						<li>
	// 							<code>reproxy.route</code> - source route (location)
	// 						</li>
	// 						<li>
	// 							<code>reproxy.dest</code> - destination path. Note: this
	// 							is not full url, but just the path which will be
	// 							appended to container’s ip:port
	// 						</li>
	// 						<li>
	// 							<code>reproxy.port</code> - destination port for the
	// 							discovered container
	// 						</li>
	// 						<li>
	// 							<code>reproxy.ping</code> - ping path for the
	// 							destination container.
	// 						</li>
	// 						<li>
	// 							<code>reproxy.assets</code> - set assets mapping as
	// 							<code>web-root:location</code>, for example
	// 							<code>reproxy.assets=/web:/var/www</code>
	// 						</li>
	// 						<li>
	// 							<code>reproxy.enabled</code> - enable (<code>yes</code>,
	// 							<code>true</code>, <code>1</code>) or disable (
	// 							<code>no</code>, <code>false</code>, <code>0</code>)
	// 							container from reproxy destinations.
	// 						</li>
	// 					</ul>
	// 					<p>
	// 						Pls note: without <code>--docker.auto</code> the
	// 						destination container has to have at least one of
	// 						<code>reproxy.*</code> labels to be considered as a
	// 						potential destination.
	// 					</p>
	// 					<p>
	// 						With <code>--docker.auto</code>, all containers with
	// 						exposed port will be considered as routing destinations.
	// 						There are 3 ways to restrict it:
	// 					</p>
	// 					<ul>
	// 						<li>
	// 							Exclude some containers explicitly with
	// 							<code>--docker.exclude</code>, i.e.
	// 							<code>--docker.exclude=c1 --docker.exclude=c2 ...</code>
	// 						</li>
	// 						<li>
	// 							Allow only a particular docker network with
	// 							<code>--docker.network</code>
	// 						</li>
	// 						<li>
	// 							Set the label <code>reproxy.enabled=false</code> or
	// 							<code>reproxy.enabled=no</code> or
	// 							<code>reproxy.enabled=0</code>
	// 						</li>
	// 					</ul>
	// 				</>
	// 			),
	// 			enBody: (
	// 				<>
	// 					<p>This provider uses yaml file with routing rules.</p>
	// 				</>
	// 			),
	// 		},
	// 	],
	// },
   // {
   //    ruTitle: 'SSL support2',
   //    enTitle: 'SSL support2',
   //    ruBody: (
   //       <>
   //          <p>
   //             SSL mode (by default none) can be set to <code>auto</code>{' '}
   //             (ACME/LE certificates), <code>static</code> (existing
   //             certificate) or <code>none</code>. If <code>auto</code>{' '}
   //             turned on SSL certificate will be issued automatically for
   //             all discovered server names. User can override it by
   //             setting <code>--ssl.fqdn</code> value(s)
   //          </p>
   //          <p>
   //             Reproxy allows to sanitize (remove) incoming headers by
   //             passing <code>--drop-header</code> parameter (can be
   //             repeated). This parameter can be useful to make sure some
   //             of the headers, set internally by the services, can’t be
   //             set/faked by the end user. For example if some of the
   //             services, responsible for the auth, sets{' '}
   //             <code>X-Auth-User</code> and <code>X-Auth-Token</code> it
   //             is likely makes sense to drop those headers from the
   //             incoming requests by passing{' '}
   //             <code>
   //                --drop-header=X-Auth-User --drop-header=X-Auth-Token
   //             </code>{' '}
   //             parameter or via environment{' '}
   //             <code>DROP_HEADERS=X-Auth-User,X-Auth-Token</code>
   //          </p>
   //          <p>
   //             The opposite function, setting outgoing header(s) supported
   //             as well. It can be useful in many cases, for example
   //             enforcing some custom CORS rules, security related headers
   //             and so on. This can be done with <code>--header</code>{' '}
   //             parameter (can be repeated) or env <code>HEADER</code>. For
   //             example, this is how it can be done with the docker
   //             compose:
   //          </p>
   //          <p>Users may turn the assets server on (off by default) to serve static files. As long as <code>--assets.location</code> set it treats every non-proxied request under <code>assets.root</code> as a request for static files. The assets server can be used without any proxy providers; in this mode, reproxy acts as a simple web server for the static content. Assets server also supports “spa mode” with <code>--assets.spa</code> where all not-found request forwarded to <code>index.html</code>.</p>
   //          <p>In addition to the common assets server, multiple custom assets servers are supported. Each provider has a different way to define such a static rule, and some providers may not support it at all. For example, multiple asset servers make sense in static (command line provider), file provider, and even useful with docker providers, however it makes very little sense with consul catalog provider.</p>
   //       </>
   //    ),
   //    enBody: (
   //       <>
   //          <p>
   //             SSL mode (by default none) can be set to <code>auto</code>{' '}
   //             (ACME/LE certificates), <code>static</code> (existing
   //             certificate) or <code>none</code>. If <code>auto</code>{' '}
   //             turned on SSL certificate will be issued automatically for
   //             all discovered server names. User can override it by
   //             setting <code>--ssl.fqdn</code> value(s)
   //          </p>
   //          <p>
   //             Reproxy allows to sanitize (remove) incoming headers by
   //             passing <code>--drop-header</code> parameter (can be
   //             repeated). This parameter can be useful to make sure some
   //             of the headers, set internally by the services, can’t be
   //             set/faked by the end user. For example if some of the
   //             services, responsible for the auth, sets{' '}
   //             <code>X-Auth-User</code> and <code>X-Auth-Token</code> it
   //             is likely makes sense to drop those headers from the
   //             incoming requests by passing{' '}
   //             <code>
   //                --drop-header=X-Auth-User --drop-header=X-Auth-Token
   //             </code>{' '}
   //             parameter or via environment{' '}
   //             <code>DROP_HEADERS=X-Auth-User,X-Auth-Token</code>
   //          </p>
   //          <p>
   //             The opposite function, setting outgoing header(s) supported
   //             as well. It can be useful in many cases, for example
   //             enforcing some custom CORS rules, security related headers
   //             and so on. This can be done with <code>--header</code>{' '}
   //             parameter (can be repeated) or env <code>HEADER</code>. For
   //             example, this is how it can be done with the docker
   //             compose:
   //          </p>
   //          <p>Users may turn the assets server on (off by default) to serve static files. As long as <code>--assets.location</code> set it treats every non-proxied request under <code>assets.root</code> as a request for static files. The assets server can be used without any proxy providers; in this mode, reproxy acts as a simple web server for the static content. Assets server also supports “spa mode” with <code>--assets.spa</code> where all not-found request forwarded to <code>index.html</code>.</p>
   //          <p>In addition to the common assets server, multiple custom assets servers are supported. Each provider has a different way to define such a static rule, and some providers may not support it at all. For example, multiple asset servers make sense in static (command line provider), file provider, and even useful with docker providers, however it makes very little sense with consul catalog provider.</p>
   //       </>
   //    ),
   // },
   // {
	// 	ruTitle: 'Install3',
	// 	enTitle: 'Install3',
	// 	ruBody: (
	// 		<>
	// 			<p>
	// 				Reproxy distributed as a small self-contained binary as well as a
	// 				docker image. Both binary and image support multiple
	// 				architectures and multiple operating systems, including
	// 				linux_x86_64, linux_arm64, linux_arm, macos_x86_64, macos_arm64,
	// 				windows_x86_64 and windows_arm. We also provide both arm64 and
	// 				x86 deb and rpm packages.
	// 			</p>
	// 			<ul>
	// 				<li>
	// 					for a binary distribution download the proper file in the
   //                <a href="https://github.com/umputunreproxyreleases">release section</a>
	// 				</li>
	// 				<li>
	// 					docker container available on
	// 					<a href="https://hub.docker.com/r/umputun/reproxy">
	// 						Docker Hub
	// 					</a>
	// 					as well as on
	// 					<a href="https://github.com/users/umputun/packages/container/reproxy/versions">
	// 						Github Container Registry
	// 					</a>
	// 					. I.e. <code>docker pull umputun/reproxy</code> or
	// 					<code>docker pull ghcr.io/umputun/reproxy</code>.
	// 				</li>
	// 			</ul>
	// 			<p>
	// 				Latest stable version has <code>:vX.Y.Z</code> docker tag (with
	// 				<code>:latest</code> alias) and the current master has
	// 				<code>:master</code> tag.
	// 			</p>
	// 		</>
	// 	),
	// 	enBody: (
	// 		<>
	// 			<p>
	// 				Reproxy distributed as a small self-contained binary as well as a
	// 				docker image. Both binary and image support multiple
	// 				architectures and multiple operating systems, including
	// 				linux_x86_64, linux_arm64, linux_arm, macos_x86_64, macos_arm64,
	// 				windows_x86_64 and windows_arm. We also provide both arm64 and
	// 				x86 deb and rpm packages.
	// 			</p>
	// 			<ul>
	// 				<li>
	// 					for a binary distribution download the proper file in the <a href="https://github.com/umputun/reproxy/releases">release section</a>
	// 				</li>
	// 				<li>
	// 					docker container available on <a href="https://hub.docker.com/r/umputun/reproxy">Docker Hub</a> as well as on <a href="https://github.com/users/umputun/packages/container/reproxy/versions">Github Container Registry</a>. I.e. <code>docker pull umputun/reproxy</code> or <code>docker pull ghcr.io/umputun/reproxy</code>.
	// 				</li>
	// 			</ul>
	// 			<p>
	// 				Latest stable version has <code>:vX.Y.Z</code> docker tag (with
	// 				<code>:latest</code> alias) and the current master has
	// 				<code>:master</code> tag.
	// 			</p>
	// 		</>
	// 	),
	// },
	// {
	// 	ruTitle: 'Providers3',
	// 	enTitle: 'Providers3',
	// 	ruBody: (
	// 		<>
	// 			<p>
	// 				Proxy rules supplied by various providers. Currently included -
	// 				<code>file</code>, <code>docker</code>, <code>static</code> and
	// 				<code>consul-catalog</code>. Each provider may define multiple
	// 				routing rules for both proxied request and static (assets). User
	// 				can sets multiple providers at the same time.
	// 			</p>
	// 			<p>
	// 				<em>
	// 					See examples of various providers in
	// 					<a href="https://github.com/umputun/reproxy/tree/master/examples">
	// 						examples
	// 					</a>
	// 				</em>
	// 			</p>
	// 		</>
	// 	),
	// 	enBody: (
	// 		<>
	// 			<p>
	// 				Proxy rules supplied by various providers. Currently included -
	// 				<code>file</code>, <code>docker</code>, <code>static</code> and
	// 				<code>consul-catalog</code>. Each provider may define multiple
	// 				routing rules for both proxied request and static (assets). User
	// 				can sets multiple providers at the same time.
	// 			</p>
	// 			<p>
	// 				<em>
	// 					See examples of various providers in
	// 					<a href="https://github.com/umputun/reproxy/tree/master/examples">
	// 						examples
	// 					</a>
	// 				</em>
	// 			</p>
	// 		</>
	// 	),
	// 	children: [
	// 		{
	// 			ruTitle: 'Static provider3',
	// 			enTitle: 'Static provider3',
	// 			ruBody: (
	// 				<>
	// 					<p>
	// 						This is the simplest provider defining all mapping rules
	// 						directly in the command line (or environment). Multiple
	// 						rules supported. Each rule is 3 or 4 comma-separated
	// 						elements
	// 						<code>server,sourceurl,destination[,ping-url]</code>. For
	// 						example:
	// 					</p>
	// 					<ul>
	// 						<li>
	// 							<code>*,^/api/(.*),https://api.example.com/$1</code> -
	// 							proxy all request to any host/server with
	// 							<code>/api</code> prefix to
	// 							<code>https://api.example.com</code>
	// 						</li>
	// 						<li>
	// 							<code>
	// 								example.com,/foo/bar,https://api.example.com/zzz,https://api.example.com/ping
	// 							</code>
	// 							- proxy all requests to <code>example.com</code> and
	// 							with <code>/foo/bar</code> url to
	// 							<code>https://api.example.com/zzz</code> and it sses
	// 							<code>https://api.example.com/ping</code> for the health
	// 							check.
	// 						</li>
	// 					</ul>
	// 					<p>
	// 						The last (4<sup>th</sup>) element defines an optional ping
	// 						url used for health reporting. I.e.
	// 						<code>
	// 							*,^/api/(.*),https://api.example.com/$1,https://api.example.com/ping
	// 						</code>
	// 						. See <a href="#ping-and-health-checks">Health check</a>
	// 						section for more details.
	// 					</p>
	// 				</>
	// 			),
	// 			enBody: (
	// 				<>
	// 					<p>
	// 						This is the simplest provider defining all mapping rules
	// 						directly in the command line (or environment). Multiple
	// 						rules supported. Each rule is 3 or 4 comma-separated
	// 						elements
	// 						<code>server,sourceurl,destination[,ping-url]</code>. For
	// 						example:
	// 					</p>
	// 					<ul>
	// 						<li>
	// 							<code>*,^/api/(.*),https://api.example.com/$1</code> -
	// 							proxy all request to any host/server with
	// 							<code>/api</code> prefix to
	// 							<code>https://api.example.com</code>
	// 						</li>
	// 						<li>
	// 							<code>
	// 								example.com,/foo/bar,https://api.example.com/zzz,https://api.example.com/ping
	// 							</code>
	// 							- proxy all requests to <code>example.com</code> and
	// 							with <code>/foo/bar</code> url to
	// 							<code>https://api.example.com/zzz</code> and it sses
	// 							<code>https://api.example.com/ping</code> for the health
	// 							check.
	// 						</li>
	// 					</ul>
	// 					<p>
	// 						The last (4<sup>th</sup>) element defines an optional ping
	// 						url used for health reporting. I.e.
	// 						<code>
	// 							*,^/api/(.*),https://api.example.com/$1,https://api.example.com/ping
	// 						</code>
	// 						. See <a href="#ping-and-health-checks">Health check</a>
	// 						section for more details.
	// 					</p>
	// 				</>
	// 			),
	// 		},
	// 		{
	// 			ruTitle: 'File provider3',
	// 			enTitle: 'File provider3',
	// 			ruBody: (
	// 				<>
	// 					<p>This provider uses yaml file with routing rules.</p>
	// 				</>
	// 			),
	// 			enBody: (
	// 				<>
	// 					<p>This provider uses yaml file with routing rules.</p>
	// 				</>
	// 			),
	// 		},
	// 		{
	// 			ruTitle: 'Docker provider3',
	// 			enTitle: 'Docker provider3',
	// 			ruBody: (
	// 				<>
	// 					<p>
	// 						Docker provider supports a fully automatic discovery (with
	// 						<code>--docker.auto</code>) with no extra configuration
	// 						needed. By default, it redirects all requests like
	// 						<code>http://&lt;url&gt;/&lt;container name&gt;/(.*)</code>
	// 						to the internal IP of the given container and the exposed
	// 						port. Only active (running) containers will be detected.
	// 					</p>
	// 					<p>This default can be changed with labels:</p>
	// 					<ul>
	// 						<li>
	// 							<code>reproxy.server</code> - server (hostname) to
	// 							match. Also can be a list of comma-separated servers.
	// 						</li>
	// 						<li>
	// 							<code>reproxy.route</code> - source route (location)
	// 						</li>
	// 						<li>
	// 							<code>reproxy.dest</code> - destination path. Note: this
	// 							is not full url, but just the path which will be
	// 							appended to container’s ip:port
	// 						</li>
	// 						<li>
	// 							<code>reproxy.port</code> - destination port for the
	// 							discovered container
	// 						</li>
	// 						<li>
	// 							<code>reproxy.ping</code> - ping path for the
	// 							destination container.
	// 						</li>
	// 						<li>
	// 							<code>reproxy.assets</code> - set assets mapping as
	// 							<code>web-root:location</code>, for example
	// 							<code>reproxy.assets=/web:/var/www</code>
	// 						</li>
	// 						<li>
	// 							<code>reproxy.enabled</code> - enable (<code>yes</code>,
	// 							<code>true</code>, <code>1</code>) or disable (
	// 							<code>no</code>, <code>false</code>, <code>0</code>)
	// 							container from reproxy destinations.
	// 						</li>
	// 					</ul>
	// 					<p>
	// 						Pls note: without <code>--docker.auto</code> the
	// 						destination container has to have at least one of
	// 						<code>reproxy.*</code> labels to be considered as a
	// 						potential destination.
	// 					</p>
	// 					<p>
	// 						With <code>--docker.auto</code>, all containers with
	// 						exposed port will be considered as routing destinations.
	// 						There are 3 ways to restrict it:
	// 					</p>
	// 					<ul>
	// 						<li>
	// 							Exclude some containers explicitly with
	// 							<code>--docker.exclude</code>, i.e.
	// 							<code>--docker.exclude=c1 --docker.exclude=c2 ...</code>
	// 						</li>
	// 						<li>
	// 							Allow only a particular docker network with
	// 							<code>--docker.network</code>
	// 						</li>
	// 						<li>
	// 							Set the label <code>reproxy.enabled=false</code> or
	// 							<code>reproxy.enabled=no</code> or
	// 							<code>reproxy.enabled=0</code>
	// 						</li>
	// 					</ul>
	// 				</>
	// 			),
	// 			enBody: (
	// 				<>
	// 					<p>This provider uses yaml file with routing rules.</p>
	// 				</>
	// 			),
	// 		},
	// 	],
	// },
   // {
   //    ruTitle: 'SSL support3',
   //    enTitle: 'SSL support3',
   //    ruBody: (
   //       <>
   //          <p>
   //             SSL mode (by default none) can be set to <code>auto</code>{' '}
   //             (ACME/LE certificates), <code>static</code> (existing
   //             certificate) or <code>none</code>. If <code>auto</code>{' '}
   //             turned on SSL certificate will be issued automatically for
   //             all discovered server names. User can override it by
   //             setting <code>--ssl.fqdn</code> value(s)
   //          </p>
   //          <p>
   //             Reproxy allows to sanitize (remove) incoming headers by
   //             passing <code>--drop-header</code> parameter (can be
   //             repeated). This parameter can be useful to make sure some
   //             of the headers, set internally by the services, can’t be
   //             set/faked by the end user. For example if some of the
   //             services, responsible for the auth, sets{' '}
   //             <code>X-Auth-User</code> and <code>X-Auth-Token</code> it
   //             is likely makes sense to drop those headers from the
   //             incoming requests by passing{' '}
   //             <code>
   //                --drop-header=X-Auth-User --drop-header=X-Auth-Token
   //             </code>{' '}
   //             parameter or via environment{' '}
   //             <code>DROP_HEADERS=X-Auth-User,X-Auth-Token</code>
   //          </p>
   //          <p>
   //             The opposite function, setting outgoing header(s) supported
   //             as well. It can be useful in many cases, for example
   //             enforcing some custom CORS rules, security related headers
   //             and so on. This can be done with <code>--header</code>{' '}
   //             parameter (can be repeated) or env <code>HEADER</code>. For
   //             example, this is how it can be done with the docker
   //             compose:
   //          </p>
   //          <p>Users may turn the assets server on (off by default) to serve static files. As long as <code>--assets.location</code> set it treats every non-proxied request under <code>assets.root</code> as a request for static files. The assets server can be used without any proxy providers; in this mode, reproxy acts as a simple web server for the static content. Assets server also supports “spa mode” with <code>--assets.spa</code> where all not-found request forwarded to <code>index.html</code>.</p>
   //          <p>In addition to the common assets server, multiple custom assets servers are supported. Each provider has a different way to define such a static rule, and some providers may not support it at all. For example, multiple asset servers make sense in static (command line provider), file provider, and even useful with docker providers, however it makes very little sense with consul catalog provider.</p>
   //       </>
   //    ),
   //    enBody: (
   //       <>
   //          <p>
   //             SSL mode (by default none) can be set to <code>auto</code>{' '}
   //             (ACME/LE certificates), <code>static</code> (existing
   //             certificate) or <code>none</code>. If <code>auto</code>{' '}
   //             turned on SSL certificate will be issued automatically for
   //             all discovered server names. User can override it by
   //             setting <code>--ssl.fqdn</code> value(s)
   //          </p>
   //          <p>
   //             Reproxy allows to sanitize (remove) incoming headers by
   //             passing <code>--drop-header</code> parameter (can be
   //             repeated). This parameter can be useful to make sure some
   //             of the headers, set internally by the services, can’t be
   //             set/faked by the end user. For example if some of the
   //             services, responsible for the auth, sets{' '}
   //             <code>X-Auth-User</code> and <code>X-Auth-Token</code> it
   //             is likely makes sense to drop those headers from the
   //             incoming requests by passing{' '}
   //             <code>
   //                --drop-header=X-Auth-User --drop-header=X-Auth-Token
   //             </code>{' '}
   //             parameter or via environment{' '}
   //             <code>DROP_HEADERS=X-Auth-User,X-Auth-Token</code>
   //          </p>
   //          <p>
   //             The opposite function, setting outgoing header(s) supported
   //             as well. It can be useful in many cases, for example
   //             enforcing some custom CORS rules, security related headers
   //             and so on. This can be done with <code>--header</code>{' '}
   //             parameter (can be repeated) or env <code>HEADER</code>. For
   //             example, this is how it can be done with the docker
   //             compose:
   //          </p>
   //          <p>Users may turn the assets server on (off by default) to serve static files. As long as <code>--assets.location</code> set it treats every non-proxied request under <code>assets.root</code> as a request for static files. The assets server can be used without any proxy providers; in this mode, reproxy acts as a simple web server for the static content. Assets server also supports “spa mode” with <code>--assets.spa</code> where all not-found request forwarded to <code>index.html</code>.</p>
   //          <p>In addition to the common assets server, multiple custom assets servers are supported. Each provider has a different way to define such a static rule, and some providers may not support it at all. For example, multiple asset servers make sense in static (command line provider), file provider, and even useful with docker providers, however it makes very little sense with consul catalog provider.</p>
   //       </>
   //    ),
   // },
   // {
	// 	ruTitle: 'Install4',
	// 	enTitle: 'Install4',
	// 	ruBody: (
	// 		<>
	// 			<p>
	// 				Reproxy distributed as a small self-contained binary as well as a
	// 				docker image. Both binary and image support multiple
	// 				architectures and multiple operating systems, including
	// 				linux_x86_64, linux_arm64, linux_arm, macos_x86_64, macos_arm64,
	// 				windows_x86_64 and windows_arm. We also provide both arm64 and
	// 				x86 deb and rpm packages.
	// 			</p>
	// 			<ul>
	// 				<li>
	// 					for a binary distribution download the proper file in the
   //                <a href="https://github.com/umputunreproxyreleases">release section</a>
	// 				</li>
	// 				<li>
	// 					docker container available on
	// 					<a href="https://hub.docker.com/r/umputun/reproxy">
	// 						Docker Hub
	// 					</a>
	// 					as well as on
	// 					<a href="https://github.com/users/umputun/packages/container/reproxy/versions">
	// 						Github Container Registry
	// 					</a>
	// 					. I.e. <code>docker pull umputun/reproxy</code> or
	// 					<code>docker pull ghcr.io/umputun/reproxy</code>.
	// 				</li>
	// 			</ul>
	// 			<p>
	// 				Latest stable version has <code>:vX.Y.Z</code> docker tag (with
	// 				<code>:latest</code> alias) and the current master has
	// 				<code>:master</code> tag.
	// 			</p>
	// 		</>
	// 	),
	// 	enBody: (
	// 		<>
	// 			<p>
	// 				Reproxy distributed as a small self-contained binary as well as a
	// 				docker image. Both binary and image support multiple
	// 				architectures and multiple operating systems, including
	// 				linux_x86_64, linux_arm64, linux_arm, macos_x86_64, macos_arm64,
	// 				windows_x86_64 and windows_arm. We also provide both arm64 and
	// 				x86 deb and rpm packages.
	// 			</p>
	// 			<ul>
	// 				<li>
	// 					for a binary distribution download the proper file in the <a href="https://github.com/umputun/reproxy/releases">release section</a>
	// 				</li>
	// 				<li>
	// 					docker container available on <a href="https://hub.docker.com/r/umputun/reproxy">Docker Hub</a> as well as on <a href="https://github.com/users/umputun/packages/container/reproxy/versions">Github Container Registry</a>. I.e. <code>docker pull umputun/reproxy</code> or <code>docker pull ghcr.io/umputun/reproxy</code>.
	// 				</li>
	// 			</ul>
	// 			<p>
	// 				Latest stable version has <code>:vX.Y.Z</code> docker tag (with
	// 				<code>:latest</code> alias) and the current master has
	// 				<code>:master</code> tag.
	// 			</p>
	// 		</>
	// 	),
	// },
	// {
	// 	ruTitle: 'Providers4',
	// 	enTitle: 'Providers4',
	// 	ruBody: (
	// 		<>
	// 			<p>
	// 				Proxy rules supplied by various providers. Currently included -
	// 				<code>file</code>, <code>docker</code>, <code>static</code> and
	// 				<code>consul-catalog</code>. Each provider may define multiple
	// 				routing rules for both proxied request and static (assets). User
	// 				can sets multiple providers at the same time.
	// 			</p>
	// 			<p>
	// 				<em>
	// 					See examples of various providers in
	// 					<a href="https://github.com/umputun/reproxy/tree/master/examples">
	// 						examples
	// 					</a>
	// 				</em>
	// 			</p>
	// 		</>
	// 	),
	// 	enBody: (
	// 		<>
	// 			<p>
	// 				Proxy rules supplied by various providers. Currently included -
	// 				<code>file</code>, <code>docker</code>, <code>static</code> and
	// 				<code>consul-catalog</code>. Each provider may define multiple
	// 				routing rules for both proxied request and static (assets). User
	// 				can sets multiple providers at the same time.
	// 			</p>
	// 			<p>
	// 				<em>
	// 					See examples of various providers in
	// 					<a href="https://github.com/umputun/reproxy/tree/master/examples">
	// 						examples
	// 					</a>
	// 				</em>
	// 			</p>
	// 		</>
	// 	),
	// 	children: [
	// 		{
	// 			ruTitle: 'Static provider4',
	// 			enTitle: 'Static provider4',
	// 			ruBody: (
	// 				<>
	// 					<p>
	// 						This is the simplest provider defining all mapping rules
	// 						directly in the command line (or environment). Multiple
	// 						rules supported. Each rule is 3 or 4 comma-separated
	// 						elements
	// 						<code>server,sourceurl,destination[,ping-url]</code>. For
	// 						example:
	// 					</p>
	// 					<ul>
	// 						<li>
	// 							<code>*,^/api/(.*),https://api.example.com/$1</code> -
	// 							proxy all request to any host/server with
	// 							<code>/api</code> prefix to
	// 							<code>https://api.example.com</code>
	// 						</li>
	// 						<li>
	// 							<code>
	// 								example.com,/foo/bar,https://api.example.com/zzz,https://api.example.com/ping
	// 							</code>
	// 							- proxy all requests to <code>example.com</code> and
	// 							with <code>/foo/bar</code> url to
	// 							<code>https://api.example.com/zzz</code> and it sses
	// 							<code>https://api.example.com/ping</code> for the health
	// 							check.
	// 						</li>
	// 					</ul>
	// 					<p>
	// 						The last (4<sup>th</sup>) element defines an optional ping
	// 						url used for health reporting. I.e.
	// 						<code>
	// 							*,^/api/(.*),https://api.example.com/$1,https://api.example.com/ping
	// 						</code>
	// 						. See <a href="#ping-and-health-checks">Health check</a>
	// 						section for more details.
	// 					</p>
	// 				</>
	// 			),
	// 			enBody: (
	// 				<>
	// 					<p>
	// 						This is the simplest provider defining all mapping rules
	// 						directly in the command line (or environment). Multiple
	// 						rules supported. Each rule is 3 or 4 comma-separated
	// 						elements
	// 						<code>server,sourceurl,destination[,ping-url]</code>. For
	// 						example:
	// 					</p>
	// 					<ul>
	// 						<li>
	// 							<code>*,^/api/(.*),https://api.example.com/$1</code> -
	// 							proxy all request to any host/server with
	// 							<code>/api</code> prefix to
	// 							<code>https://api.example.com</code>
	// 						</li>
	// 						<li>
	// 							<code>
	// 								example.com,/foo/bar,https://api.example.com/zzz,https://api.example.com/ping
	// 							</code>
	// 							- proxy all requests to <code>example.com</code> and
	// 							with <code>/foo/bar</code> url to
	// 							<code>https://api.example.com/zzz</code> and it sses
	// 							<code>https://api.example.com/ping</code> for the health
	// 							check.
	// 						</li>
	// 					</ul>
	// 					<p>
	// 						The last (4<sup>th</sup>) element defines an optional ping
	// 						url used for health reporting. I.e.
	// 						<code>
	// 							*,^/api/(.*),https://api.example.com/$1,https://api.example.com/ping
	// 						</code>
	// 						. See <a href="#ping-and-health-checks">Health check</a>
	// 						section for more details.
	// 					</p>
	// 				</>
	// 			),
	// 		},
	// 		{
	// 			ruTitle: 'File provider4',
	// 			enTitle: 'File provider4',
	// 			ruBody: (
	// 				<>
	// 					<p>This provider uses yaml file with routing rules.</p>
	// 				</>
	// 			),
	// 			enBody: (
	// 				<>
	// 					<p>This provider uses yaml file with routing rules.</p>
	// 				</>
	// 			),
	// 		},
	// 		{
	// 			ruTitle: 'Docker provider4',
	// 			enTitle: 'Docker provider4',
	// 			ruBody: (
	// 				<>
	// 					<p>
	// 						Docker provider supports a fully automatic discovery (with
	// 						<code>--docker.auto</code>) with no extra configuration
	// 						needed. By default, it redirects all requests like
	// 						<code>http://&lt;url&gt;/&lt;container name&gt;/(.*)</code>
	// 						to the internal IP of the given container and the exposed
	// 						port. Only active (running) containers will be detected.
	// 					</p>
	// 					<p>This default can be changed with labels:</p>
	// 					<ul>
	// 						<li>
	// 							<code>reproxy.server</code> - server (hostname) to
	// 							match. Also can be a list of comma-separated servers.
	// 						</li>
	// 						<li>
	// 							<code>reproxy.route</code> - source route (location)
	// 						</li>
	// 						<li>
	// 							<code>reproxy.dest</code> - destination path. Note: this
	// 							is not full url, but just the path which will be
	// 							appended to container’s ip:port
	// 						</li>
	// 						<li>
	// 							<code>reproxy.port</code> - destination port for the
	// 							discovered container
	// 						</li>
	// 						<li>
	// 							<code>reproxy.ping</code> - ping path for the
	// 							destination container.
	// 						</li>
	// 						<li>
	// 							<code>reproxy.assets</code> - set assets mapping as
	// 							<code>web-root:location</code>, for example
	// 							<code>reproxy.assets=/web:/var/www</code>
	// 						</li>
	// 						<li>
	// 							<code>reproxy.enabled</code> - enable (<code>yes</code>,
	// 							<code>true</code>, <code>1</code>) or disable (
	// 							<code>no</code>, <code>false</code>, <code>0</code>)
	// 							container from reproxy destinations.
	// 						</li>
	// 					</ul>
	// 					<p>
	// 						Pls note: without <code>--docker.auto</code> the
	// 						destination container has to have at least one of
	// 						<code>reproxy.*</code> labels to be considered as a
	// 						potential destination.
	// 					</p>
	// 					<p>
	// 						With <code>--docker.auto</code>, all containers with
	// 						exposed port will be considered as routing destinations.
	// 						There are 3 ways to restrict it:
	// 					</p>
	// 					<ul>
	// 						<li>
	// 							Exclude some containers explicitly with
	// 							<code>--docker.exclude</code>, i.e.
	// 							<code>--docker.exclude=c1 --docker.exclude=c2 ...</code>
	// 						</li>
	// 						<li>
	// 							Allow only a particular docker network with
	// 							<code>--docker.network</code>
	// 						</li>
	// 						<li>
	// 							Set the label <code>reproxy.enabled=false</code> or
	// 							<code>reproxy.enabled=no</code> or
	// 							<code>reproxy.enabled=0</code>
	// 						</li>
	// 					</ul>
	// 				</>
	// 			),
	// 			enBody: (
	// 				<>
	// 					<p>This provider uses yaml file with routing rules.</p>
	// 				</>
	// 			),
	// 		},
	// 	],
	// },
   // {
   //    ruTitle: 'SSL support4',
   //    enTitle: 'SSL support4',
   //    ruBody: (
   //       <>
   //          <p>
   //             SSL mode (by default none) can be set to <code>auto</code>{' '}
   //             (ACME/LE certificates), <code>static</code> (existing
   //             certificate) or <code>none</code>. If <code>auto</code>{' '}
   //             turned on SSL certificate will be issued automatically for
   //             all discovered server names. User can override it by
   //             setting <code>--ssl.fqdn</code> value(s)
   //          </p>
   //          <p>
   //             Reproxy allows to sanitize (remove) incoming headers by
   //             passing <code>--drop-header</code> parameter (can be
   //             repeated). This parameter can be useful to make sure some
   //             of the headers, set internally by the services, can’t be
   //             set/faked by the end user. For example if some of the
   //             services, responsible for the auth, sets{' '}
   //             <code>X-Auth-User</code> and <code>X-Auth-Token</code> it
   //             is likely makes sense to drop those headers from the
   //             incoming requests by passing{' '}
   //             <code>
   //                --drop-header=X-Auth-User --drop-header=X-Auth-Token
   //             </code>{' '}
   //             parameter or via environment{' '}
   //             <code>DROP_HEADERS=X-Auth-User,X-Auth-Token</code>
   //          </p>
   //          <p>
   //             The opposite function, setting outgoing header(s) supported
   //             as well. It can be useful in many cases, for example
   //             enforcing some custom CORS rules, security related headers
   //             and so on. This can be done with <code>--header</code>{' '}
   //             parameter (can be repeated) or env <code>HEADER</code>. For
   //             example, this is how it can be done with the docker
   //             compose:
   //          </p>
   //          <p>Users may turn the assets server on (off by default) to serve static files. As long as <code>--assets.location</code> set it treats every non-proxied request under <code>assets.root</code> as a request for static files. The assets server can be used without any proxy providers; in this mode, reproxy acts as a simple web server for the static content. Assets server also supports “spa mode” with <code>--assets.spa</code> where all not-found request forwarded to <code>index.html</code>.</p>
   //          <p>In addition to the common assets server, multiple custom assets servers are supported. Each provider has a different way to define such a static rule, and some providers may not support it at all. For example, multiple asset servers make sense in static (command line provider), file provider, and even useful with docker providers, however it makes very little sense with consul catalog provider.</p>
   //       </>
   //    ),
   //    enBody: (
   //       <>
   //          <p>
   //             SSL mode (by default none) can be set to <code>auto</code>{' '}
   //             (ACME/LE certificates), <code>static</code> (existing
   //             certificate) or <code>none</code>. If <code>auto</code>{' '}
   //             turned on SSL certificate will be issued automatically for
   //             all discovered server names. User can override it by
   //             setting <code>--ssl.fqdn</code> value(s)
   //          </p>
   //          <p>
   //             Reproxy allows to sanitize (remove) incoming headers by
   //             passing <code>--drop-header</code> parameter (can be
   //             repeated). This parameter can be useful to make sure some
   //             of the headers, set internally by the services, can’t be
   //             set/faked by the end user. For example if some of the
   //             services, responsible for the auth, sets{' '}
   //             <code>X-Auth-User</code> and <code>X-Auth-Token</code> it
   //             is likely makes sense to drop those headers from the
   //             incoming requests by passing{' '}
   //             <code>
   //                --drop-header=X-Auth-User --drop-header=X-Auth-Token
   //             </code>{' '}
   //             parameter or via environment{' '}
   //             <code>DROP_HEADERS=X-Auth-User,X-Auth-Token</code>
   //          </p>
   //          <p>
   //             The opposite function, setting outgoing header(s) supported
   //             as well. It can be useful in many cases, for example
   //             enforcing some custom CORS rules, security related headers
   //             and so on. This can be done with <code>--header</code>{' '}
   //             parameter (can be repeated) or env <code>HEADER</code>. For
   //             example, this is how it can be done with the docker
   //             compose:
   //          </p>
   //          <p>Users may turn the assets server on (off by default) to serve static files. As long as <code>--assets.location</code> set it treats every non-proxied request under <code>assets.root</code> as a request for static files. The assets server can be used without any proxy providers; in this mode, reproxy acts as a simple web server for the static content. Assets server also supports “spa mode” with <code>--assets.spa</code> where all not-found request forwarded to <code>index.html</code>.</p>
   //          <p>In addition to the common assets server, multiple custom assets servers are supported. Each provider has a different way to define such a static rule, and some providers may not support it at all. For example, multiple asset servers make sense in static (command line provider), file provider, and even useful with docker providers, however it makes very little sense with consul catalog provider.</p>
   //       </>
   //    ),
   // },
];

export default knowledgeBase;
export {firstBlockText};
