import Container from '../../UI/Container/Container'
import styles from './Logotype.module.css'
import freeKassa from '../../../assets/img/payment-logo/freekassa.png'
import crystalPay from '../../../assets/img/payment-logo/crystalpay.png'
import lava from '../../../assets/img/payment-logo/lava.svg'
import mir from '../../../assets/img/payment-logo/mir.svg'
import visa from '../../../assets/img/payment-logo/visa.png'
import mastercard from '../../../assets/img/payment-logo/mastercard.png'
import btc from '../../../assets/img/payment-logo/btc.png'
import eth from '../../../assets/img/payment-logo/eth.png'

const Logotype = props => {
   return <section className={styles.logotype}>
      <div className={styles.container}>
         <div className={styles.inner}>
            <img data-aos="fade-up" data-aos-delay="100" data-aos-duration="300" src={freeKassa} alt='free kassa' />
            <img data-aos="fade-up" data-aos-delay="200" data-aos-duration="300" src={crystalPay} alt='crystal pay' />
            <img data-aos="fade-up" data-aos-delay="300" data-aos-duration="300" src={lava} alt='lava' />
            <img data-aos="fade-up" data-aos-delay="400" data-aos-duration="300" className={styles.visa} src={visa} alt='visa' />
            <img data-aos="fade-up" data-aos-delay="500" data-aos-duration="300" src={mastercard} alt='mastercard' />
            <img data-aos="fade-up" data-aos-delay="600" data-aos-duration="300" className={styles.mir} src={mir} alt='mir' />
            <img data-aos="fade-up" data-aos-delay="700" data-aos-duration="300" src={btc} alt='bitcoin' />
            <img data-aos="fade-up" data-aos-delay="800" data-aos-duration="300" src={eth} alt='etherium' />
         </div>
      </div>
   </section>
}

export default Logotype