import React from "react"

export default [
   {
      ruTitle: 'Вы предоставляете прокси протоколов IPv4 и IPv6 в чем отличие?',
      ruBody: <p>
            Если выражаться максимально доступно, то адреса формата IPv6
         отличаются разрядностью, они пришли на смену формату IPv4, но еще
         не набрали такой же популярности среди пользователей, в силу того,
         что не все сайты с ними работают. Более подробную информацию по
         протоколу вы можете изучить на
            <a href="https://ru.wikipedia.org/wiki/IPv6"> Википедии</a>  . Но
         стоит заметить, что прокси IPv6 активно используются юзерами и
         показали себя с хорошей стороны при работе с такими соц.сетми:
         Instagram, Google, FaceBook, Вконтакте, Telegram, они уже
         поддерживают данный протокол IP-адреса. Прокси формата IPv6 не
         уступают по качеству и имеют такую же анонимность, как и IPv4
         proxy . Настройка такая же простая и ничем не отличается от
         настройки IPv4 адреса.
         </p>,
      enTitle: 'Do you provide IPv4 and IPv6 proxy protocols what is the difference?',
      enBody: <p>If expressed as accessible as possible, then IPv6 addresses differ in bitness, they replaced the IPv4 format, but still did not gain the same popularity among users, due to the fact that that not all sites work with them. More information on protocol you can study at <a href="https://ru.wikipedia.org/wiki/IPv6"> Wikipedia</a> . But it is worth noting that IPv6 proxies are actively used by users and showed themselves on the good side when working with such social networks: Instagram, Google, FaceBook, Vkontakte, Telegram, they already support this IP address protocol. IPv6 format proxy is not inferior in quality and have the same anonymity as IPv4 proxy . The setup is just as simple and no different from IPv4 address settings.</p>   
   },
   {
      ruTitle: 'Я оплатил услугу, что дальше?',
      ruBody: <p>
      После оформления заказа (указав все необходимое для создания
       заказа и оплатив его), вам на почту придет код доступа, с помощью
       которого вы сможете авторизоваться в Личном кабинете, и управлять
       своими услугами, заказами и не только.
     </p>,
     enTitle: 'I paid for the service, what next?',
     enBody: <p>After placing your order (including everything you need to create your order and pay for it), you will receive an access code in your email
      which you can authorize in the Personal Cabinet, and manage
      Your services, orders and more.
    </p>
   },
   {
      ruTitle: 'Могу ли я продлить свои прокси?',
      ruBody: <p>
      После оформления заказа (указав все необходимое для создания
       заказа и оплатив его), вам на почту придет код доступа, с помощью
       которого вы сможете авторизоваться в Личном кабинете, и управлять
       своими услугами, заказами и не только.
     </p>,
     enTitle: 'Can I renew my proxies?',
     enBody: <p>After placing your order (including everything you need to create your order and pay for it), you will receive an access code in your email
      which you can authorize in the Personal Cabinet, and manage
      Your services, orders and more.
    </p>,
   },
   {
      ruTitle: 'Я оплатил услугу, что дальше?',
      ruBody: <><p>
            Конечно, вы можете продлевать свои заказы на периоды от месяца и
      более при этом уменьшая, расширяя их при необходимости.
         </p>
         <p>
         Если период действия заказа исчерпан, вы можете восстановить заказ
         самостоятельно в личном кабинете либо обратиться к консультанту
         или <a href="/support"> в поддержку </a>  за помощью.
         </p>
         </>,
      enTitle: 'I paid for the service, what next?',
      enBody: <><p>
      Of course, you can extend your bookings for periods ranging from a month to
more while reducing, expanding them if necessary.
   </p>
   <p>
   If the validity period of the order has expired, you can restore the order
   on your own in your personal account or contact a consultant
   or <a href="/support"> to support</a> for help.
   </p>
   </>
   },
   
]