import styles from './Title.module.css'

const Title = props => {

   let classes = styles.title

   if (props.className) {
      classes = `${styles.title} ${props.className}`
   }
   
   return <h2 className={classes}>
      {props.text}
  </h2>
}

export default Title