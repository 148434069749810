import HeaderMobLink from './HeaderMobLink'
import style from './HeaderMobMenu.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import HeaderMobAnchorLink from './HeaderMobAnchorLink'
import {FormattedMessage} from 'react-intl'
import HeaderMobAnchorLinkWithReload from './HeaderMobAnchorLinkWithReload/HeaderMobAnchorLinkWithReload'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import enFlag from '../../../assets/img/united-kingdom.webp'
import ruFlag from '../../../assets/img/russia.webp'


const HeaderMobMenu = props => {


   let links;

	if (props.isHomePage) {
		links = (
			<Fragment>
				<HeaderMobAnchorLink setIsHeaderOpen={props.setIsHeaderOpen}
					name={<FormattedMessage id="menu-whywe" />}
					anchor="whywe"
				/>
				<HeaderMobAnchorLink setIsHeaderOpen={props.setIsHeaderOpen}
					name={<FormattedMessage id="menu-faq" />}
					anchor="faq"
				/>
				<HeaderMobAnchorLink setIsHeaderOpen={props.setIsHeaderOpen}
					name={<FormattedMessage id="menu-price" />}
					anchor="price"
				/>
            <HeaderMobAnchorLink setIsHeaderOpen={props.setIsHeaderOpen}
					name={<FormattedMessage id="menu-news" />}
					anchor="news"
				/>
			</Fragment>
		);
	} else {
		links = (
			<Fragment>
				<HeaderMobAnchorLinkWithReload setLoadHomeWithAnchor={props.setLoadHomeWithAnchor} setIsHeaderOpen={props.setIsHeaderOpen}
					name={<FormattedMessage id="menu-whywe" />}
					link="whywe"
				/>
				<HeaderMobAnchorLinkWithReload setLoadHomeWithAnchor={props.setLoadHomeWithAnchor} setIsHeaderOpen={props.setIsHeaderOpen}
					name={<FormattedMessage id="menu-faq" />}
					link="faq"
				/>
				<HeaderMobAnchorLinkWithReload setLoadHomeWithAnchor={props.setLoadHomeWithAnchor} setIsHeaderOpen={props.setIsHeaderOpen}
					name={<FormattedMessage id="menu-price" />}
					link="price"
				/>
            <HeaderMobAnchorLinkWithReload setLoadHomeWithAnchor={props.setLoadHomeWithAnchor} setIsHeaderOpen={props.setIsHeaderOpen}
					name={<FormattedMessage id="menu-news" />}
					link="news"
				/>
			</Fragment>
		);
	}

   function ruLangHandler() {
      props.setLang('RUSSIAN')
      localStorage.setItem('lang', 'RUSSIAN')
      props.setIsHeaderOpen(false)
   }

   function enLangHandler() {
      props.setLang('ENGLISH')
      localStorage.setItem('lang', 'ENGLISH')
      props.setIsHeaderOpen(false)
   }

   return <nav className={style.header__mobmenu + ' ' + (props.isOpen ? style.open : '')}>
      <span onClick={() => props.setIsHeaderOpen(false)} className={style.close}><FontAwesomeIcon icon={faXmark} /></span>
      <ul className={style['header__mob-list']}>
         <li className={style.sigin}>
            <Link onClick={() => props.setIsHeaderOpen(false)} className={style.sigin__btn} to={'/signin'}><FormattedMessage id='menu-sigin'/></Link>
         </li>
         <li className={style.lang}>
            <span onClick={ruLangHandler} className={style.lang__btn + ` ${props.lang === 'RUSSIAN' ? style.current : ''}`}><img src={ruFlag} alt="russia flag"/>Русский</span>
            <span onClick={enLangHandler} className={style.lang__btn + ` ${props.lang === 'ENGLISH' ? style.current : ''}`}><img src={enFlag} alt="english flag"/>English</span>
         </li>
         <HeaderMobLink name={<FormattedMessage id="menu-main"/>} link={'/'} setIsHeaderOpen={props.setIsHeaderOpen}/>
         {links}
         <HeaderMobLink name={<FormattedMessage id="menu-ipv6"/>} link={'/check-ipv6'} setIsHeaderOpen={props.setIsHeaderOpen}/>
      </ul>
   </nav>
}

export default HeaderMobMenu