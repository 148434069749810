import { useEffect, useState } from 'react'
import { Link } from 'react-scroll'
import styles from './KnowledgeBaseNavItemChildren.module.css'

const KnowledgeBaseNavItemChildren = props => {

   const [linkClasses, setLinkClasses] = useState(styles.link__btn)

   useEffect(() => {
      window.addEventListener('scroll', e => {
         if (document.documentElement.scrollTop > props.endChild) {
            setLinkClasses(styles.link__btn + ' ' + styles.link__past)
         }

         if (document.documentElement.scrollTop < props.startChild) {
            setLinkClasses(styles.link__btn)
         }

         if (props.endChild > document.documentElement.scrollTop && document.documentElement.scrollTop > props.startChild) {
            setLinkClasses(styles.link__btn + ' ' + styles.link__active)
         }
      })
   }, [])

   return <div className={styles.child}>
      <Link className={linkClasses} to={props.id} spy={true} smooth={true} offset={-130} duration={500} >{props.title}</Link>
   </div>
}

export default KnowledgeBaseNavItemChildren