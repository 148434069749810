import styles from './HeaderMobBtn.module.css'

const HeaderMobBtn = props => {
   return <div onClick={() => props.setIsHeaderOpen(true)} className={styles['header__mob-btn']}>
      <span></span>
      <span></span>
      <span></span>
   </div>
}

export default HeaderMobBtn