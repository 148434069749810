import { LOCALES } from "../locales"

const en = {
   [LOCALES.ENGLISH]: {
      'menu-main': 'Home',
      'menu-whywe': 'Why us?',
      'menu-faq': 'FAQ',
      'menu-price': 'Prices',
      'menu-news': 'News',
      'footer-terms': 'User Agreement',
      'footer-privacy': 'Privacy Policy',
      'footer-contacts': 'Contacts',
      'footer-knowledge-base': 'Knowledge base',
      'footer-copy': '© 2022 Asaproxy',
      'footer-text': 'Asaproxy - the best and most reliable proxy',
      'footer-ipv6': 'Checking IPv6 support',
      'footer-tools': 'Tools',
      'footer-info': 'Information',
      'description-title': 'Reliable rotating server IPv6 proxies',
      'description-text': 'Rotation per request, time-based rotation, excellent speed, unlimited traffic',
      'description-btn': 'Buy a subscription',
      'whywe-title': 'why us?',
      'whywe-subtitle': 'we are the best offer in the market, excellent quality service',
      'whywe-adv-title-1': 'IPv6 Billions',
      'whywe-adv-text-1': 'European subnets with the cleanest addresses',
      'whywe-adv-title-2': 'Convenient management',
      'whywe-adv-text-2': 'Manage your Telegram subscription',
      'whywe-adv-title-3': 'Rotating',
      'whywe-adv-text-3': 'Proxy changes each request',
      'whywe-adv-title-4': 'Possible customisation',
      'whywe-adv-text-4': 'Build your own rate',
      'faq-title': 'FAQ',
      'faq-subtitle': 'Answers to frequently asked questions',
      'faq-additional-text': 'Any questions? We also have a Knowledge Base (extended FAQ).',
      'faq-additional-btn': 'Knowledge Base',
      'price-title': 'Prices',
      'price-subtitle': 'standard subscription prices',
      'price-btn': 'Buy now',
      'price-period-1': '1 day',
      'price-period-2': '7 days',
      'price-period-3': '30 days',
      'price-best-value': 'Best choice',
      'reviews-title': 'отзывы наших клиентов',
      'reviews-subtitle': 'мы стараемся предоставлять лучший клиентский сервис.',
      'news-btn': 'Read full',
      'ipv6-result-good': 'Отличная новость, sitename поддерживает IPv6',
      'ipv6-result-bad': 'К сожалению, sitename на данный момент не поддерживает IPv6',
   }
}

export default en