import styles from './HeaderAnchorLink.module.css'
import * as Scroll from 'react-scroll';

const Link = Scroll.Link


const HeaderAnchorLink = props => {
   return <li className={styles.header__item}>
      <Link to={props.anchor} href={props.anchor} spy={true} smooth={true} offset={-100} duration={500} className={styles.header__link}>{props.name}</Link>
   </li>
}

export default HeaderAnchorLink;