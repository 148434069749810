import styles from './Contacts.module.css'
import "aos/dist/aos.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faCircleQuestion, faRobot } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import Aos from 'aos'
import { FormattedMessage } from 'react-intl'
import {Helmet} from "react-helmet";


const Contacts = props => {


   useEffect(() => {
      Aos.init({duration: 1000, disable: window.innerWidth < 990})
      props.setIsHomePage(false)
   }, [])



   return <main>
      <Helmet>
         <meta charSet="utf-8" />
         <title>Наши контакты - ASAproxy</title>
         <meta name="description" content="Ротация на каждый запрос, ротация по времени, отличная скорость, безлимитный трафик" />
      </Helmet>
      <section className={styles.contacts}>
         <div className={styles.container}>
            <div className={styles.inner}>
               <div className={styles.map} data-aos="fade-right">
                  <iframe title="Our map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2001.2113063369036!2d20.462488663081444!3d44.81870896438699!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a7ab40c8b07c1%3A0xd8d366bbaa67b840!2z0JTQvtGB0LjRgtC10ZjQtdCy0LAgMTksINCR0LXQvtCz0YDQsNC0LCDQodC10YDQsdC40Y8!5e0!3m2!1sru!2sua!4v1660727613541!5m2!1sru!2sua" width="100%" height="800px" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
               </div>
               <div className={styles.info}>
                  <h1 className={styles.title} data-aos="fade-right" data-aos-duration="600"><FormattedMessage id="contacts-title" /></h1>
                  <p className={styles.text} data-aos="fade-right" data-aos-duration="600" data-aos-delay="300"><FormattedMessage id="contacts-subtitle" /></p>
                  <div className={styles.email} data-aos="fade-right" data-aos-duration="600" data-aos-delay="600">
                     <h4 className={styles.label}><FontAwesomeIcon icon={faEnvelope} /> Email</h4>
                     <a className={styles.link} href="mailto:template@gmail.com">template@gmail.com</a>
                  </div>
                  <div className={styles.additional}>
                     <div className={styles.additional__item} data-aos="fade-right" data-aos-duration="600" data-aos-delay="900">
                        <h4 className={styles.label}><FontAwesomeIcon icon={faCircleQuestion} /> TG support</h4>
                        <a className={styles.link} rel="noreferrer" target="_blank" href="https://t.me/asaproxy_support">@asaproxy_support</a>
                     </div>
                     <div className={styles.additional__item} data-aos="fade-right" data-aos-duration="600" data-aos-delay="1200">
                        <h4 className={styles.label}><FontAwesomeIcon icon={faRobot} /> TG bot</h4>
                        <a className={styles.link} rel="noreferrer" target="_blank" href="https://t.me/asaproxybot">@asaproxybot</a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </main>
}

export default Contacts