import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import styles from './HeaderSignIn.module.css'

const HeaderSignIn = props => {
   return <div className={styles.signin__wrapper}>
      <Link to={'/sign-in'} className={styles.sigin} ><span><FormattedMessage id='menu-sigin' /></span></Link>
   </div>
}

export default HeaderSignIn