import styles from './Link.module.css'

const Link = props => {

   let classes = styles.button

   if (props.className) {
      classes = styles.button + ' ' + props.className
   }

   return <a className={classes} href={props.link}>
      {props.text}
   </a>
}

export default Link