export default function getSysLang() {
	let languageVar = navigator.language || navigator.userLanguage;

	languageVar = languageVar.substr(0, 2).toLowerCase();

	let lang = '';

	switch (languageVar) {
		case 'ru':
			lang = 'RUSSIAN';
			break;
		case 'uk':
			lang = 'RUSSIAN';
			break;
		case 'kz':
			lang = 'RUSSIAN';
			break;
		case 'be':
			lang = 'RUSSIAN';
			break;
		case 'mo':
			lang = 'RUSSIAN';
			break;
		case 'hy':
			lang = 'RUSSIAN';
			break;
		case 'ky':
			lang = 'RUSSIAN';
			break;
		case 'tg':
			lang = 'RUSSIAN';
			break;
		case 'uz':
			lang = 'RUSSIAN';
			break;
		default:
			lang = 'ENGLISH';
			break;
	}

   return lang;
}
