import Container from '../../UI/Container/Container'
import styles from './Description.module.css'
// import World from './World'
import {FormattedMessage} from 'react-intl'
import "aos/dist/aos.css"
import { lazy, Suspense } from 'react'

const World = lazy(() => import ('./World'))

const Description = props => {

   let world = ''

   if (window.innerWidth > '990') {
      world = <World />
   }

   return <section className={styles.description}>
      <Container>
         <div className={styles.description__inner}>
            <div className={styles.description__content} data-aos="fade-right">
               <h1 className={styles.description__title}><FormattedMessage id='description-title' /></h1>
               <p className={styles.description__text}><FormattedMessage id='description-text' /></p>
               <a href="/" className={styles.description__btn}>
                  <span className={styles.circle} aria-hidden="true">
                     <span className={styles.icon + ' ' + styles.arrow}></span>
                  </span>
                  <span className={styles['button-text']}><FormattedMessage id='description-btn' /></span>
               </a>
            </div>
            <Suspense>
               {world}
            </Suspense>
         </div>
      </Container>
   </section>
}

export default Description