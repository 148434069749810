import axios from "axios"


const api = {
   checkIpv6(url) {
      return axios
      .head(`https://starfish-app-92ss8.ondigitalocean.app/api/web/has-aaaa-record?uri=${url}`)
      .then(res => res)
   }
}

export default api