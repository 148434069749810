import { Link } from 'react-router-dom';
import styles from './HeaderLink.module.css'
import { useLocation } from 'react-router-dom';
import * as Scroll from 'react-scroll';
var scroll = Scroll.animateScroll;


const HeaderLink = props => {
   const location = useLocation();
   function checkSameUrlHandler(e) {
      if (location.pathname === props.link) {
         e.preventDefault()
         scroll.scrollToTop({duration: 500});
      }
   }
   return <li className={styles.header__item}>
      <Link to={props.link} className={styles.header__link} onClick={checkSameUrlHandler}>{props.name} </Link>
   </li>
}

export default HeaderLink;