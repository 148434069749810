import styles from './AdvantageCard.module.css'

const AdvantageCard = props => {
   return <div className={styles.card} data-aos="zoom-in" data-aos-duration="500">
      <div className={styles.card__inner}>
         <div className={styles.card__icon}>
            <img src={props.icon} alt="advantages icon" />
         </div>
         <div className={styles.card__info}>
            <h4 className={styles.card__title}>{props.title}</h4>
            <p className={styles.card__text}>{props.text}</p>
         </div>
      </div>
   </div>
}

export default AdvantageCard