import { Link } from 'react-router-dom';
import styles from './HeaderAnchorLinkWithReload.module.css'


const HeaderAnchorLinkWithReload = props => {

   const clickHandler = () => {
      console.log(props.link)
      props.setLoadHomeWithAnchor(props.link)
   }

   return <li className={styles.header__item}>
      <Link to={'/'} onClick={clickHandler} className={styles.header__link}>{props.name}</Link>
   </li>
}

export default HeaderAnchorLinkWithReload;