import styles from './SectionHeader.module.css'
import "aos/dist/aos.css"


const SectionHeader = props => {
   return <div className={styles.section__header} data-aos="fade-up" data-aos-duration="500">
      {props.children}
   </div>
}

export default SectionHeader