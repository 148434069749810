import Container from '../../UI/Container/Container'
import Subtitle from '../../UI/Subtitle/Subtitle'
import Title from '../../UI/Title/Title'
import styles from './Whywe.module.css'
import wirelessIcon from '../../../assets/img/advantages/wireless.png'
import controlIcon from '../../../assets/img/advantages/control.png'
import rotateIcon from '../../../assets/img/advantages/rotate.png'
import customIcon from '../../../assets/img/advantages/custom.png'
import AdvantageCard from './AdvantageCard'
import SectionHeader from '../../UI/SectionHeader/SectionHeader'
import {FormattedMessage} from 'react-intl'


const advantages = [
   {
      title: <FormattedMessage id="whywe-adv-title-1" />,
      text: <FormattedMessage id="whywe-adv-text-1" />,
      icon: wirelessIcon
   },
   {
      title: <FormattedMessage id="whywe-adv-title-2" />,
      text: <FormattedMessage id="whywe-adv-text-2" />,
      icon: controlIcon
   },
   {
      title: <FormattedMessage id="whywe-adv-title-3" />,
      text: <FormattedMessage id="whywe-adv-text-3" />,
      icon: rotateIcon
   },
   {
      title: <FormattedMessage id="whywe-adv-title-4" />,
      text: <FormattedMessage id="whywe-adv-text-4" />,
      icon: customIcon
   },
]

const Whywe = props => {


   const cardList = advantages.map((item, index) => <AdvantageCard title={item.title} text={item.text} icon={item.icon} key={index} />)

   return <section className={styles.whywe} id="whywe">
      <Container>
         <SectionHeader>
            <Title text={<FormattedMessage id="whywe-title" />} />
            <Subtitle text={<FormattedMessage id="whywe-subtitle" />} />
         </SectionHeader>
         <div className={styles.inner}>
            {cardList}
         </div>
      </Container>
   </section>
}

export default Whywe