import styles from './HeaderLang.module.css'
import enFlag from '../../assets/img/united-kingdom.webp'
import ruFlag from '../../assets/img/russia.webp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'


const HeaderLang = props => {

   const [isClickedLang, setIsClickedLang] = useState(false)

   useEffect(() => {
      if (!isClickedLang) {
         return
      }

      console.log('useeffect')
      
      const timeout = setTimeout(() => {
         setIsClickedLang(false)
      }, 100)
      return () => {
         clearTimeout(timeout)
      }
   }, [isClickedLang])

   const setRuLang = e => {
      e.preventDefault()
      setIsClickedLang(true)
      props.setLang('RUSSIAN')
      localStorage.setItem('lang', 'RUSSIAN')
   }
   
   const setEnLang = e => {
      e.preventDefault()
      setIsClickedLang(true)
      props.setLang('ENGLISH')
      localStorage.setItem('lang', 'ENGLISH')
   }

   return <li className={styles.header__lang}>
      {/* <a href="#ru" onClick={setRuLang} className={styles.header__switcher}>RU</a>
      <span className={styles.header__separator}></span>
      <a href="#en" onClick={setEnLang} className={styles.header__switcher}>EN</a> */}
      <div className={styles['header__lang-wrapper']}>
         {props.lang === "ENGLISH" ? <span className={styles.header__en}><img src={enFlag} alt="uk flag" /> English <span className={styles.arrow}><FontAwesomeIcon icon={faAngleDown}/></span></span> : ''}
         {props.lang === "RUSSIAN" ? <span className={styles.header__ru}><img src={ruFlag} alt="ru flag" /> Русский <span className={styles.arrow}><FontAwesomeIcon icon={faAngleDown}/></span></span> : ''}
         <ul className={styles['header__lang-list'] + ` ${isClickedLang ? styles.close : ''}`}>
            <li className={styles['header__lang-item'] + ` ${ props.lang === 'ENGLISH' ? styles.current : ''}`} onClick={setEnLang}>
               <span className={styles.header__en}><img src={enFlag} alt="uk flag" /> English</span>
            </li>
            <li className={styles['header__lang-item']  + ` ${ props.lang === 'RUSSIAN' ? styles.current : ''}`} onClick={setRuLang}>
               <span className={styles.header__ru}><img src={ruFlag} alt="ru flag" /> Русский</span>
            </li>
         </ul>
      </div>
   </li>
}

export default HeaderLang