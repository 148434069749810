import { useEffect } from 'react'
import Description from './Description/Description'
import Faq from './Faq/Faq'
import styles from './Home.module.css'
import Logotype from './Logotype/Logotype'
import Reviews from './Reviews/Reviews'
import Price from './Price/Price'
import Whywe from './Whywe/Whywe'
import { Helmet } from 'react-helmet'
import footerLogo from '../../assets/img/logo-pretty.svg'


const Home = props => {

   useEffect(()=> {
      if (props.loadHomeWithAnchor) {
          const elem = document.getElementById(props.loadHomeWithAnchor)
          const elementPosition = elem.getBoundingClientRect().top - 100;
          if (elem) {
            window.scrollTo({
               top: elementPosition,
               behavior: "smooth",
             });
          }
          props.setLoadHomeWithAnchor(null)
      }
   }, [props])

   useEffect(() => {
      props.setIsHomePage(true)
   }, [])


   return <main className={styles.main} id="home">
      <Helmet>
         <meta charSet="utf-8" />
         <title>Надежные ротационные серверные IPv6 прокси - ASAproxy</title>
         <meta name="description" content="Ротация на каждый запрос, ротация по времени, отличная скорость, безлимитный трафик" />
         <link rel="preload" href={footerLogo} as="image" />
      </Helmet>
      <Description />
      <Whywe />
      <Faq />
      <Price />
      <Logotype />
      <Reviews />
   </main>
}

export default Home