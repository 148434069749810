import { Fragment } from "react"
import Footer from "../Footer/Footer"
import Header from "../Header/Header"
import {Outlet} from 'react-router-dom'

const Layout = props => {
   return <Fragment>
      <Header lang={props.lang} setLang={props.setLang} isHomePage={props.isHomePage} setLoadHomeWithAnchor={props.setLoadHomeWithAnchor}/>
      <Outlet />
      <Footer />
   </Fragment>
}

export default Layout