import styles from './Header.module.css';
import logo from '../../assets/img/logo-rectangle-pretty.svg';
import HeaderLink from './HeaderLink';
import HeaderLang from './HeaderLang';
import Container from '../UI/Container/Container';
import HeaderMobBtn from './HeaderMob/HeaderMobBtn';
import HeaderMobMenu from './HeaderMob/HeaderMobMenu';
import { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import HeaderAnchorLink from './HeaderAnchorLink/HeaderAnchorLink';
import { Link } from 'react-router-dom';
import HeaderAnchorLinkWithReload from './HeaderAnchorLinkWithReload/HeaderAnchorLinkWithReload';
import HeaderSignIn from './HeaderSignIn/HeaderSignIn';

const menu = [
	{ name: <FormattedMessage id="menu-main" />, link: '/' },
	{ name: <FormattedMessage id="menu-whywe" />, link: '#whywe' },
	{ name: <FormattedMessage id="menu-faq" />, link: '#faq' },
	{ name: <FormattedMessage id="menu-price" />, link: '#price' },
	{ name: <FormattedMessage id="menu-news" />, link: '/news' },
];

const Header = props => {
	const [stickyHeader, setStickyHeader] = useState(false);
	const [isHeaderOpen, setIsHeaderOpen] = useState(false);

	window.addEventListener('scroll', e => {
		if (document.documentElement.scrollTop) {
			setStickyHeader(true);
		} else {
			setStickyHeader(false);
		}
	});

	const classesSticky = stickyHeader ? styles.sticky : '';

	let links;

	if (props.isHomePage) {
		links = (
			<Fragment>
				<HeaderAnchorLink
					name={<FormattedMessage id="menu-whywe" />}
					anchor="whywe"
				/>
				<HeaderAnchorLink
					name={<FormattedMessage id="menu-faq" />}
					anchor="faq"
				/>
				<HeaderAnchorLink
					name={<FormattedMessage id="menu-price" />}
					anchor="price"
				/>
            <HeaderAnchorLink
					name={<FormattedMessage id="menu-news" />}
					anchor="news"
				/>
			</Fragment>
		);
	} else {
		links = (
			<Fragment>
				<HeaderAnchorLinkWithReload setLoadHomeWithAnchor={props.setLoadHomeWithAnchor}
					name={<FormattedMessage id="menu-whywe" />}
					link="whywe"
				/>
				<HeaderAnchorLinkWithReload setLoadHomeWithAnchor={props.setLoadHomeWithAnchor}
					name={<FormattedMessage id="menu-faq" />}
					link="faq"
				/>
				<HeaderAnchorLinkWithReload setLoadHomeWithAnchor={props.setLoadHomeWithAnchor}
					name={<FormattedMessage id="menu-price" />}
					link="price"
				/>
            <HeaderAnchorLinkWithReload setLoadHomeWithAnchor={props.setLoadHomeWithAnchor}
					name={<FormattedMessage id="menu-news" />}
					link="news"
				/>
			</Fragment>
		);
	}

	return (
		<header className={styles.header + ' ' + classesSticky}>
			<Container>
				<div className={styles.header__inner}>
					<Link to="/">
						<img
							src={logo}
							alt="logotype"
							className={styles.header__logo}
						/>
					</Link>
					<nav className={styles.header__menu}>
						<ul className={styles['header__menu-list']}>
                  <HeaderLink link="/" name={<FormattedMessage id="menu-main"/>} />
							{links}
                     <HeaderLink link="/check-ipv6" name={<FormattedMessage id="menu-ipv6"/>} />
						</ul>
						<HeaderLang lang={props.lang} setLang={props.setLang} />
                  <HeaderSignIn />
						<HeaderMobBtn setIsHeaderOpen={setIsHeaderOpen} />
                  <HeaderMobMenu setLoadHomeWithAnchor={props.setLoadHomeWithAnchor} isHomePage={props.isHomePage}
							menu={menu}
							setIsHeaderOpen={setIsHeaderOpen}
							isOpen={isHeaderOpen}
                     lang={props.lang} setLang={props.setLang}
						/>
					</nav>
				</div>
			</Container>
		</header>
	);
};

export default Header;
