import { Link } from 'react-scroll'
import Container from '../UI/Container/Container'
import styles from './Privacy.module.css'
import privacy from '../../assets/privacy/privacy'
import { useContext, useEffect } from 'react'
import LangContext from '../../Context/LangContext'
import Aos from 'aos'
import { FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'


const Privacy = props => {

   useEffect(() => {
      Aos.init({ duration: 1000, disable: window.innerWidth < 1650 });
      props.setIsHomePage(false)
   }, [])



   const lang = useContext(LangContext)

   let contenList

   let navigation

   if (lang === 'RUSSIAN') {
      contenList = privacy.map((item, index) => {
         return <div id={item.enTitle.trim()} key={index}>
            <h2 className={styles.content__title} data-aos="fade-left" data-aos-duration="500">{item.ruTitle}</h2>
            {item.ruBody}
         </div>
      })

      navigation = privacy.map((item, index) => {
         return <li className={styles.navigation__item} key={index}>
            <Link to={item.enTitle.trim()} spy={true} smooth={true} offset={-150} duration={500} >{index + 1}. {item.ruTitle}</Link>
         </li>
      })
   } else {
      contenList = privacy.map((item, index) => {
         return <div id={item.enTitle.trim()} key={index}>
            <h2 className={styles.content__title} data-aos="fade-left" data-aos-duration="500">{item.enTitle}</h2>
            {item.enBody}
         </div>
      })

      navigation = privacy.map((item, index) => {
         return <li className={styles.navigation__item} key={index}>
            <Link to={item.enTitle.trim()} spy={true} smooth={true} offset={-150} duration={500} >{index + 1}. {item.enTitle}</Link>
         </li>
      })
   }

   return <main>
      <Helmet>
         <meta charSet="utf-8" />
         <title>Политика конфиденциальности - ASAproxy</title>
         <meta name="description" content="Ротация на каждый запрос, ротация по времени, отличная скорость, безлимитный трафик" />
      </Helmet>
      <section className={styles.privacy}>
         <Container>
            <h1 className={styles.title} data-aos="fade-down"><FormattedMessage id="privacy-title" /></h1>
            <div className={styles.inner}>
               <div className={styles.content}>
                  {contenList}
               </div>
               <nav className={styles.navigation} data-aos="fade-left">
                  <ul className={styles.navigation__list}>
                     {navigation}
                  </ul>
               </nav>
            </div>
         </Container>
      </section>
   </main>
}

export default Privacy