import styles from './Loader.module.css'

const Loader = props => {
   return <div className={styles.wrapper}>
   <div className={styles.circle}></div>
   <div className={styles.circle}></div>
   <div className={styles.circle}></div>
   <div className={styles.shadow}></div>
   <div className={styles.shadow}></div>
   <div className={styles.shadow}></div>
</div>
}

export default Loader;
