import Container from '../../UI/Container/Container'
import SectionHeader from '../../UI/SectionHeader/SectionHeader'
import Subtitle from '../../UI/Subtitle/Subtitle'
import Title from '../../UI/Title/Title'
import styles from './Reviews.module.css'
import {FormattedMessage} from 'react-intl'
import ReviewsItem from './ReviewsItem'

const news = [
   {
      id: 1,
      title: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit voluptas cupiditate quas praesentium labore excepturi in distinctio perferendis unde earum soluta, impedit temporibus expedita. Aut dolores voluptas aliquid qui corporis.',
      date: '13.08.2022',
   },
   {
      id: 2,
      title: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit voluptas cupiditate quas praesentium labore excepturi in distinctio perferendis unde earum soluta, impedit temporibus expedita. Aut dolores voluptas aliquid qui corporis.',
      date: '14.08.2022',
   },
   {
      id: 3,
      title: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit voluptas cupiditate quas praesentium labore excepturi in distinctio perferendis unde earum soluta, impedit temporibus expedita. Aut dolores voluptas aliquid qui corporis.',
      date: '15.08.2022',
   }
]

const Reviews = props => {

   const newsList = news.map((item, index) => <ReviewsItem delay={index * 300} title={item.title} text={item.text} date={item.date} link={'/news/' + item.id} key={item.id} />)

   return <section className={styles.news} id="news">
      <Container>
         <SectionHeader>
            <Title text={<FormattedMessage id="reviews-title" />} />
            <Subtitle text={<FormattedMessage id="reviews-subtitle" />} />
         </SectionHeader>
         <div className={styles.inner}>
            {newsList}
         </div>
      </Container>
   </section>
}

export default Reviews