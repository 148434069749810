import styles from './Subtitle.module.css'

const Subtitle = props => {

   let classes = styles.subtitle;

   if (props.className) {
      classes = styles.subtitle + ' ' + props.className
   }

   return <p className={classes}>
      {props.text}
   </p>
}

export default Subtitle