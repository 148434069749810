import { useState } from 'react';
import styles from './CheckBox.module.css';
import {
  animated,
  useSpring,
  config,
  useSpringRef,
  useChain
} from "react-spring";
import { Link } from 'react-router-dom';

const CheckBox = props => {

  const checkboxAnimationRef = useSpringRef();
  const checkboxAnimationStyle = useSpring({
    backgroundColor: props.isChecked ? "#1B9CFC" : "#fff",
    borderColor: props.isChecked ? "#1B9CFC" : "#ddd",
    config: config.gentle,
    ref: checkboxAnimationRef
  });

  const [checkmarkLength, setCheckmarkLength] = useState(null);

  const checkmarkAnimationRef = useSpringRef();
  const checkmarkAnimationStyle = useSpring({
    x: props.isChecked ? 0 : checkmarkLength,
    config: config.gentle,
    ref: checkmarkAnimationRef
  });

  useChain(
    props.isChecked
      ? [checkboxAnimationRef, checkmarkAnimationRef]
      : [checkmarkAnimationRef, checkboxAnimationRef],
    [0, 0.1]
  );
	return (
		<label className={styles.label}>
			<input
				type="checkbox"
				onChange={() => {
					props.setIsChecked(!props.isChecked);
				}}
			/>
          <animated.svg
            style={checkboxAnimationStyle}
            className={styles.checkbox +
					' ' +
					(props.isChecked ? styles['checkbox--active'] : '')}
            aria-hidden="true"
            viewBox="0 0 15 11"
            fill="none"
            >
            <animated.path
               d="M1 4.5L5 9L14 1"
               strokeWidth="2"
               stroke="#fff"
               ref={(ref) => {
                  if (ref) {
                  setCheckmarkLength(ref.getTotalLength());
                  }
               }}
               strokeDasharray={checkmarkLength}
               strokeDashoffset={checkmarkAnimationStyle.x}
            />
            </animated.svg>
         {props.label}
		</label>
	);
};

export default CheckBox;
