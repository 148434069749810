import Link from '../../UI/Link/Link'
import styles from './ReviewsItem.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import {FormattedMessage} from 'react-intl'
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons'


const ReviewsItem = props => {

   const text = props.text.slice(0, 158) + '..' 

   return <div className={styles.card} data-aos="flip-right" data-aos-duration="800" data-aos-delay={props.delay}>
      <FontAwesomeIcon icon={faQuoteLeft} className={styles.quote} />
      <a className={styles.title__link} href='#'><h4 className={styles.title}>{props.title}</h4></a>
      <p className={styles.text}>{text}</p>
      <span className={styles.date}><FontAwesomeIcon className={ styles.date__icon }icon={faCalendarDays} /> {props.date}</span>
      <Link text={<FormattedMessage id="news-btn"/>} link={props.link} className={styles.btn} />
   </div>
}

export default ReviewsItem
