import styles from './KnowledgeBase.module.css';
import { useContext, useEffect, useRef, useState } from 'react';
import Aos from 'aos';
import knowledgeBase, { firstBlockText } from '../../assets/knowledgeBase/knowledgeBase-text';
import KnowledgeBaseItem from './KnowledgeBaseItem';
import Container from '../UI/Container/Container';
import LangContext from '../../Context/LangContext';
import KnowledgeBaseItemChildren from './KnowledgeBaseItemChildren';
import KnowledgeBaseNavItem from './KnowledgeBaseNavItem';
import KnowledgeBaseNavItemChildren from './KnowledgeBaseNavItemChildren';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import {FormattedMessage} from 'react-intl'


const KnowledgeBase = props => {
	const itemsRef = useRef([]);
   const [blockMount, setBlockMount] = useState(false)
   const [listNav, setListNav] = useState()
   const [isOpenMenu, setIsOpenMenu] = useState(false)

   const lang = useContext(LangContext)

	useEffect(() => {
		Aos.init({ duration: 1000, disable: window.innerWidth < 1650 });
		props.setIsHomePage(false);
	}, []);
   console.log('comp start')
   useEffect(() => {
         console.log('useeffect')
      const tmp = knowledgeBase.map((item, index) => {
         console.log('start')
         const rect = itemsRef.current[index][0].getBoundingClientRect();
         const bound = itemsRef.current[index][0].offsetTop
         const start = bound - 20
         const end = bound + rect.height - 20
         // console.log(bound - 10)
         // console.log(rect.height)
         let childList = ''

         if (item.children) {

            childList = item.children.map((item, childrenIndex) => {

               const rectChild = itemsRef.current[index][childrenIndex + 1].getBoundingClientRect();
               const boundChild = itemsRef.current[index][childrenIndex + 1].offsetTop


               const startChild = boundChild - 20
               const endChild = boundChild - 20 + rectChild.height

               if (lang === 'RUSSIAN') {
                  return <KnowledgeBaseNavItemChildren startChild={startChild} endChild={endChild} title={item.ruTitle} key={childrenIndex} id={item.enTitle.toLowerCase().replace(' ', '-')} />
               } else if (lang === 'ENGLISH') {
                  return <KnowledgeBaseNavItemChildren startChild={startChild} endChild={endChild} title={item.enTitle} key={childrenIndex} id={item.enTitle.toLowerCase().replace(' ', '-')} />
               }

            })
         }

         if (lang === 'RUSSIAN') {
            return <KnowledgeBaseNavItem start={start} end={end} title={item.ruTitle} key={index} id={item.enTitle.toLowerCase().replace(' ', '-')} childList={childList}/>
         } else if (lang === 'ENGLISH') {
            return <KnowledgeBaseNavItem start={start} end={end} title={item.enTitle} key={index} id={item.enTitle.toLowerCase().replace(' ', '-')} childList={childList} />
         }
      });

      setListNav(tmp)
   }, [itemsRef])

	const listBody = knowledgeBase.map((item, index) => {
      let childList

      itemsRef.current[index] = []
      let refFunc = el => (itemsRef.current[index][0] = el)
      if (item.children) {
         childList = item.children.map((item, childrenIndex) => {
            let refFuncChild = el => (itemsRef.current[index][childrenIndex + 1] = el)

            if (lang === 'RUSSIAN') {
               return <KnowledgeBaseItemChildren title={item.ruTitle} body={item.ruBody} child={item.children} ref={refFuncChild} key={childrenIndex} id={item.enTitle.toLowerCase().replace(' ', '-')} />
            } else if (lang === 'ENGLISH') {
               return <KnowledgeBaseItemChildren title={item.enTitle} body={item.enBody} child={item.children} ref={refFuncChild} key={childrenIndex} id={item.enTitle.toLowerCase().replace(' ', '-')} />
            }
         })
      }


      if (lang === 'RUSSIAN') {
         return <KnowledgeBaseItem title={item.ruTitle} body={item.ruBody} ref={refFunc} key={index} id={item.enTitle.toLowerCase().replace(' ', '-')} childList={childList}/>
      } else if (lang === 'ENGLISH') {
         return <KnowledgeBaseItem title={item.enTitle} body={item.enBody}  ref={refFunc} key={index} id={item.enTitle.toLowerCase().replace(' ', '-')} childList={childList}/>
      }
   });

let firstBlock

if (lang === 'RUSSIAN') {
   firstBlock = <KnowledgeBaseItem title={firstBlockText.ruTitle} body={firstBlockText.ruBody} id={firstBlockText.enTitle.toLowerCase().replace(' ', '-')} />
} else if (lang === 'ENGLISH') {
   firstBlock = <KnowledgeBaseItem title={firstBlockText.enTitle} body={firstBlockText.enBody} id={firstBlockText.enTitle.toLowerCase().replace(' ', '-')}/>
}

// setBlockMount(true)



	return (
		<main>
         <Container>
            <div className={styles.wrapper}>
               <div className={styles.inner}>
                  <aside className={styles.sidebar + ' ' + (isOpenMenu && styles.open)} data-aos="fade-right">
                     <span className={styles.aside__close} onClick={() => setIsOpenMenu(false)}><FontAwesomeIcon icon={ faXmark } /></span>
                     <div className={styles.scroll}>
                        <KnowledgeBaseNavItem type="main" id="asaproxy" title="ASAproxy" />
                        <nav className={styles.nav}>
                           {listNav}
                        </nav>
                     </div>
                  </aside>
                  <section className={styles.body}>
               <h1 className={styles.title} data-aos="fade-down"><FormattedMessage id="knowledge-title" /></h1>
                     <div className={styles.content}>
                        {firstBlock}
                        {listBody}
                     </div>
                  </section>
               </div>
            </div>
         </Container>
         <div className={styles.aside__btn + ' ' + (isOpenMenu && styles.close)} onClick={() => setIsOpenMenu(true)}>
            <span></span>
            <span></span>
            <span></span>
         </div>
		</main>
	);
};

export default KnowledgeBase;
