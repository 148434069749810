import { useState, useEffect, useRef, Suspense, lazy } from 'react';
import './App.css';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import { I18nProvider, LOCALES } from './i18n'
import getSysLang from './i18n/langByDefault';
import LangContext from './Context/LangContext'
import Aos from 'aos'
import {
   BrowserRouter,
   Routes,
   Route,
 } from "react-router-dom";
import Contacts from './components/Contacts/Contacts';
import CheckIp from './components/CheckIp/CheckIp';
import Tos from './components/Tos/Tos';
import Privacy from './components/Privacy/Privacy';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import KnowledgeBase from './components/KnowledgeBase/KnowledgeBase';
import SignIn from './components/Sign/SignIn';
import SignUp from './components/Sign/SingUp';
import Layout from './components/Layot/Layout';
import AdminLogin from './components/AdminLogin/AdminLogin';

function App() {

   Aos.init({duration: 1000, disable: window.innerWidth < 990})


   let initialLanguage = ''

      if (!localStorage.getItem('lang')) {
         initialLanguage = getSysLang();
         localStorage.setItem('lang', initialLanguage)
      } else {
         initialLanguage = localStorage.getItem('lang')
      }
      
   const [lang, setLang] = useState(initialLanguage)
   const [loadHomeWithAnchor, setLoadHomeWithAnchor] = useState(null)
   const [isHomePage, setIsHomePage] = useState(false)

  return (
   <I18nProvider locale={LOCALES[lang]}>
      <LangContext.Provider value={lang}>
         <ScrollToTop>
               {/* <Header lang={lang} setLang={setLang} isHomePage={isHomePage} setLoadHomeWithAnchor={setLoadHomeWithAnchor}/> */}
               <Routes>
                  <Route path='/' element={<Layout lang={lang} setLang={setLang} isHomePage={isHomePage} setLoadHomeWithAnchor={setLoadHomeWithAnchor}/>}>
                  {/* <Route path='/' element={<Header lang={lang} setLang={setLang} isHomePage={isHomePage} setLoadHomeWithAnchor={setLoadHomeWithAnchor}/>}> */}
                     <Route index element={<Home setIsHomePage={setIsHomePage} loadHomeWithAnchor={loadHomeWithAnchor} setLoadHomeWithAnchor={setLoadHomeWithAnchor}/>}/>
                     <Route path='/contacts' element={<Contacts setIsHomePage={setIsHomePage}/>}/>
                     <Route path='/check-ipv6' element={<CheckIp setIsHomePage={setIsHomePage}/>}/>
                     <Route path='/terms-of-use' element={<Tos setIsHomePage={setIsHomePage}/>}/>
                     <Route path='/privacy-policy' element={<Privacy setIsHomePage={setIsHomePage}/>}/>
                     <Route path='/base-of-knowledge' element={<KnowledgeBase setIsHomePage={setIsHomePage}/>}/>
                     <Route path='/sign-in' element={<SignIn setIsHomePage={setIsHomePage}/>}/>
                     <Route path='/sign-up' element={<SignUp setIsHomePage={setIsHomePage}/>}/>
                  </Route>
                  <Route path="/hhuhhdhfsuih3h9hduodhdudh" element={<AdminLogin />}/>
               </Routes>
         </ScrollToTop>
      </LangContext.Provider>
      {/* <Footer/> */}
   </I18nProvider>
  );
}

export default App;
