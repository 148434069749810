import { LOCALES } from "../locales"

const ru = {
   [LOCALES.RUSSIAN]: {
      'menu-main': 'Главная',
      'menu-whywe': 'Почему мы?',
      'menu-faq': 'FAQ',
      'menu-price': 'Цены',
      'menu-news': 'Отзывы',
      'menu-ipv6': 'Поддержка IPv6',
      'menu-sigin': 'Войти',
      'footer-terms': 'Пользовательское соглашение',
      'footer-privacy': 'Политика конфиденциальности',
      'footer-contacts': 'Контакты',
      'footer-knowledge-base': 'База знаний',
      'footer-copy': '© 2022 ASAProxy',
      'footer-text': 'ASAProxy - IPv6 прокси с ротацией для ваших целей',
      'footer-ipv6': 'Проверка поддержки IPv6',
      'footer-tools': 'Инструменты',
      'footer-info': 'Информация',
      'description-title': 'Надежные ротационные серверные IPv6 прокси',
      'description-text': 'Ротация на каждый запрос, ротация по времени, отличная скорость, безлимитный трафик',
      'description-btn': 'Купить подписку',
      'whywe-title': 'Почему мы?',
      'whywe-subtitle': 'Быстрые сервера, отличная техподдержка, высокий аптайм, различное ГЕО',
      'whywe-adv-title-1': 'Миллиарды IPv6 прокси',
      'whywe-adv-text-1': 'На собственных, трастовых префиксах',
      'whywe-adv-title-2': 'Удобное управление',
      'whywe-adv-text-2': 'Управляйте своей подпиской в Telegram',
      'whywe-adv-title-3': 'Ротация',
      'whywe-adv-text-3': 'Ротация прокси каждый запрос или по времени',
      'whywe-adv-title-4': 'Настройка подписки',
      'whywe-adv-text-4': 'Сконфигугрируйте подписку под ваши цели и бюджет',
      'faq-title': 'FAQ',
      'faq-subtitle': 'ответы на часто задаваемые вопросы',
      'faq-additional-text': 'Остались вопросы? У нас также есть База знаний (расширенный FAQ).',
      'faq-additional-btn': 'База знаний',
      'price-title': 'Цены',
      'price-subtitle': 'цены на стандартную подписку',
      'price-btn': 'Купить сейчас',
      'price-period-1': '1 день',
      'price-period-2': '7 дней',
      'price-period-3': '30 дней',
      'price-best-value': 'Лучший выбор',
      'reviews-title': 'отзывы наших клиентов',
      'reviews-subtitle': 'Мы стараемся предоставлять лучший клиентский сервис.',
      'news-btn': 'Читать полностью',
      'contacts-title': 'Наши контакты',
      'contacts-subtitle': 'Связаться с нами можно по email или telegram. Наши дата-центры располагаются в Великобритании и Германии.',
      'ipv6-title': 'Проверьте сайт на поддержку IPv6',
      'ipv6-subtitle': 'Введите адрес сайта для проверки',
      'ipv6-btn': 'Начать проверку',
      'ipv6-result-good': 'Отличная новость, {sitename} поддерживает IPv6',
      'ipv6-result-bad': 'К сожалению, {sitename} на данный момент не поддерживает IPv6',
      'tos-title': 'Пользовательское соглашение',
      'privacy-title': 'Политика конфиденциальности',
      'knowledge-title': 'База знаний',
      'signin-title': 'Вход в профиль',
      'signin-remember': 'Запомнить меня',
      'signin-forget': 'Забыли пароль?',
      'sign-signin': 'Войти',
      'signin-alternative': 'У вас еще нет профиля?',
      'sign-signup': 'Регистрация',
      'sign-error-user-title': 'Неверный формат имени пользователя.',
      'sign-error-user-text': 'Имя пользователя должно содержать от 3 до 25 символов, не должно содержать пробелы, кириллицу и спец. символы.',
      'sign-error-password-title': 'Неверный формат пароля.',
      'sign-error-password-text': 'Пароль должен содержать от 8 до 77 символов, не должен содержать пробелы и не должен совпадать с username.',
      'sign-error-repeat-title': 'Пароли не совпадают.',
      'sign-error-repeat-text': 'Ваши пароли не совпадают, проверьте внимательно.',
      'signup-title': 'Регистрация профиля',
      'signup-accept': 'Я согласен с {tos} и {privacy}',
      'signup-tos': 'Пользовательским соглашением',
      'signup-privacy': 'Политикой конфиденциальности',
      'signup-alternative': 'Уже зарегистрированы?',
   }
}

export default ru