import { useContext, useRef } from "react"
import React from "react"
import styles from './KnowledgeBaseItem.module.css'
import LangContext from "../../Context/LangContext"

const KnowledgeBaseItem = React.forwardRef((props, ref) => {


   return <div id={props.id} className={styles.section}>
      <div className={styles.body}>
         <div className={styles.bodyParent} ref={ref}>
            <h2 className={styles.title}>{props.title}</h2>
            {props.body}
         </div>
         {props.childList ? props.childList : ''}
      </div>
   </div>
})

export default KnowledgeBaseItem