import styles from './Footer.module.css'
import Container from '../UI/Container/Container'
import logo from '../../assets/img/logo-pretty.svg'
import FooterColumn from './FooterColumn'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

const infoColumn = [
   {text: <FormattedMessage id="footer-terms" />, link: '/terms-of-use'},
   {text: <FormattedMessage id="footer-privacy" />, link: '/privacy-policy'},
   {text: <FormattedMessage id="footer-contacts" />, link: '/contacts'},
   {text: <FormattedMessage id="footer-knowledge-base" />, link: '/base-of-knowledge'},
]
const toolColumn = [
   {text: <FormattedMessage id="footer-ipv6" />, link: '/check-ipv6'},
]

const Footer = props => {
   return <footer className={styles.footer}>
      <Container>
         <div className={styles.footer__inner}>
            <div className={styles.footer__copyinfo}>
               <Link to='/'><img className={styles.footer__logo} src={logo} alt="logotype" /></Link>
               <Link to='/' className={styles.footer__copy}><FormattedMessage id="footer-copy" /></Link>
               <span className={styles.footer__text}><FormattedMessage id="footer-text" /></span>
            </div>
            <FooterColumn title={<FormattedMessage id="footer-info" />} list={infoColumn}/>
            <FooterColumn title={<FormattedMessage id="footer-tools" />} list={toolColumn}/>
         </div>
      </Container>
   </footer>
}

export default Footer