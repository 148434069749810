import Container from '../../UI/Container/Container'
import SectionHeader from '../../UI/SectionHeader/SectionHeader'
import Subtitle from '../../UI/Subtitle/Subtitle'
import Title from '../../UI/Title/Title'
import styles from './Price.module.css'
import PriceCard from './PriceCard'
import {FormattedMessage} from 'react-intl'


const listOneDay = ['50k + IPv4 Proxies', 'Unlimited Bandwidth', 'Ip Authorization', 'HTTP(s)/SOCKS', '24/7 Support']

const Price = props => {
   return <section className={styles.price} id="price">
      <Container>
         <SectionHeader>
            <Title text={<FormattedMessage id='price-title' />} />
            <Subtitle text={<FormattedMessage id='price-subtitle' />} />
         </SectionHeader>
         <div className={styles.inner}>
            <PriceCard data-aos-delay="0" data-aos="flip-left" period={<FormattedMessage id='price-period-1' />} price="500" list={listOneDay} linkText={<FormattedMessage id='price-btn' />} link='/purchase'/>
            <PriceCard data-aos-delay="300" data-aos="flip-left" period={<FormattedMessage id='price-period-2' />} price="2100" list={listOneDay} linkText={<FormattedMessage id='price-btn' />} link='/purchase' className={styles.best__option} white={true} />
            <PriceCard data-aos-delay="600" data-aos="flip-left" period={<FormattedMessage id='price-period-3' />} price="6000" list={listOneDay} linkText={<FormattedMessage id='price-btn' />} link='/purchase' />
         </div>
      </Container>
   </section> 
}

export default Price