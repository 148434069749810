import CheckBox from '../UI/CheckBox/CheckBox';
import styles from './Sign.module.css';
import Aos from 'aos';
import { useEffect, useState } from 'react';
import Container from '../UI/Container/Container';
import { Link } from 'react-router-dom';
import Input from '../UI/Input/Input';
import showIcon from '../../assets/img/show.png'
import hideIcon from '../../assets/img/hide.png'
import ReactTooltip from 'react-tooltip';
import tooltipIcon from '../../assets/img/tooltip.png'
import { CSSTransition } from 'react-transition-group';
import {FormattedMessage} from 'react-intl'

const SignIn = props => {

	useEffect(() => {
		Aos.init({ duration: 1000, disable: window.innerWidth < 990 });
		props.setIsHomePage(false);
	}, []);


   const [isShowPassword, setIsShowPassword] = useState(false)
   const [isFormValid, setIsFormValid] = useState(false)
   const [isUserFieldValid, setIsUserFieldValid] = useState(true)
   const [isPassFieldValid, setIsPassFieldValid] = useState(true)
   const [inputUsername, setInputUsername] = useState(null)
   const [inputPassword, setInputPassword] = useState(null)
	const [isChecked, setIsChecked] = useState(false);


   useEffect(() => {
      if (inputUsername !== null && inputPassword !== null) { 
         setIsFormValid(!/[^\a-zA-Z0-9]+/g.test(inputUsername) && inputUsername.trim().length > 2 && inputUsername.trim().length < 26 && /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&"'.,\\\/\-_=+\(\)<>{}\[\]~`|^—«»])[A-Za-z\d@$!%*#?&"'.,\\\/\-_=+\(\)<>{}\[\]~`|^—«»]{8,77}$/.test(inputPassword) && inputPassword !== inputUsername)
      }
   }, [inputUsername, inputPassword])

   useEffect(() => {

      const timer = setTimeout(() => {
         if (inputUsername !== null) {
            setIsUserFieldValid(!/[^\a-zA-Z0-9]+/g.test(inputUsername) && inputUsername.trim().length > 2 && inputUsername.trim().length < 26)
         }
      }, 500)
            
      return () => {
         clearTimeout(timer)
      }
   }, [inputUsername])

   useEffect(() => {

      const timer = setTimeout(() => {
         if (inputPassword !== null) {
            setIsPassFieldValid(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&"'.,\\\/\-_=+\(\)<>{}\[\]~`|^—«»])[A-Za-z\d@$!%*#?&"'.,\\\/\-_=+\(\)<>{}\[\]~`|^—«»]{8,77}$/.test(inputPassword) && inputPassword !== inputUsername)
         }
      }, 500)
            
      return () => {
         clearTimeout(timer)
      }
   }, [inputPassword])


   const passwordShowHandler = e => {
      setIsShowPassword(!isShowPassword)
   }

   const usernameHandler = e => {

      if (/\s/.test(e.target.value)) {
         e.target.value = e.target.value.replace(/\s/, '');
      }

      if (/[A-Z]+/.test(e.target.value)) {
         e.target.value = e.target.value.toLowerCase()
      }
      
      setInputUsername(e.target.value)
   
   }

   const passwordHandler = e => {
      
      if (/\s/.test(e.target.value)) {
         e.target.value = e.target.value.replace(/\s/, '');
      }

      setInputPassword(e.target.value)

   }

   const fromSubmitHandler = e => {
      e.preventDefault()
      console.log({
         username: inputUsername,
         password: inputPassword,
         remember: isChecked
      })
   }


	return (
		<main>
			<section className={styles.signin}>
				<Container>
					<div className={styles.inner} data-aos="fade-up" data-aos-duration="800">
                  <div className={styles.wrapper__content} >
                     <h1 className={styles.title}><FormattedMessage id="signin-title"/></h1>
                     <form action="#" className={styles.form} onSubmit={fromSubmitHandler}>
                        <Input type="name" placeholder="Username" className={styles.form__input + ' ' + (!isUserFieldValid && styles.invalid)} onChange={usernameHandler} />
                        <div className={styles.password__inner}>
                           <Input type={isShowPassword ? "text" : "password"} placeholder="Password" onChange={passwordHandler} className={styles.form__input + ' ' + (!isPassFieldValid && styles.invalid)} />
                           <span className={styles.password__btn} onClick={passwordShowHandler}>
                              <img src={isShowPassword ? hideIcon : showIcon} alt="password icon"/>
                           </span>
                        </div>
                        <div className={styles.action}>
                           <div className={styles.remember}>
                              <CheckBox label={<FormattedMessage id="signin-remember"/>} isChecked={isChecked} setIsChecked={setIsChecked} />
                           </div>
                           <a href="https://t.me/asaproxy_support" target="_blank" className={styles.forget}><FormattedMessage id="signin-forget"/></a>   
                        </div>
                        <button disabled={!isFormValid} type="submit" className={styles.btn}><FormattedMessage id="sign-signin"/></button>
                        <div className={styles.alternative}>
                           <span className={styles.alternative__text}><FormattedMessage id="signin-alternative"/></span>
                           <Link to="/sign-up" className={styles.alternative__btn}><FormattedMessage id="sign-signup"/></Link>
                        </div> 
                     </form>
                     <CSSTransition in={!isUserFieldValid} timeout={500} classNames="showAnim" unmountOnExit>
                        <div className={styles.error} >
                           <p className={styles.error__text}><FormattedMessage id="sign-error-user-title"/></p>
                           <span className={styles.tooltip}>
                              <p data-for="user-error" data-tip><img src={tooltipIcon} className={styles.tooltip__icon} alt="tooltip icon" /></p>
                              <ReactTooltip type="dark" className={styles.tooltiparea} id="user-error">
                                  <p style={{'font-size': '1rem'}}>
                                    <FormattedMessage id="sign-error-user-text"/>
                                 </p>
                              </ReactTooltip>
                           </span>
                        </div>
                     </CSSTransition>
                     <CSSTransition in={!isPassFieldValid} timeout={500} classNames="showAnim" unmountOnExit>
                        <div className={styles.error} >
                           <p className={styles.error__text}><FormattedMessage id="sign-error-password-title"/></p>
                           <span className={styles.tooltip}>
                              <p data-for="password-error" data-tip><img src={tooltipIcon} className={styles.tooltip__icon} alt="tooltip icon" /></p>
                              <ReactTooltip type="dark" className={styles.tooltiparea} id="password-error">
                                  <p style={{'font-size': '1rem'}}>
                                    <FormattedMessage id="sign-error-password-text"/>
                                 </p>
                              </ReactTooltip>
                           </span>
                        </div>
                     </CSSTransition>
                  </div>
					</div>
				</Container>
			</section>
		</main>
	);
};

export default SignIn;
