import * as Scroll from 'react-scroll';

const Link = Scroll.Link

const HeaderMobAnchorLink = props => {
   return <li>
      <Link to={props.anchor} href={props.anchor} style={{cursor: 'pointer'}} spy={true} smooth={true} offset={-100} duration={500} onClick={() => props.setIsHeaderOpen(false)}>{props.name}</Link>
   </li>
}

export default HeaderMobAnchorLink